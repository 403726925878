/* eslint-disable react/no-unescaped-entities */
import React, {useCallback, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField} from '@mui/material';
import {Person, User} from "../../services/restserver-openapi";
import moment from 'moment';
import DatePickerVanlife from "../presentationalComponents/DatePickerVanlife";


interface RegisterPersonalInformationFormProps {
    disableNext: (value: boolean) => void;
    handleNext: () => void;
    localUserData: User;
    setLocalUserData: (value: User) => void;
}

const profileStyling = {
    textfield: {
        width: '100%',
    },
};

const RegisterPersonalInformationForm:React.FC<RegisterPersonalInformationFormProps> = ({ disableNext, handleNext, localUserData, setLocalUserData }) => {
    const handleInputChange = (property: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if (localUserData.person) {
            setLocalUserData({
                ...localUserData,
                person: { ...localUserData.person, [property]: event.target.value }
            });
        }

    };

    const [birthdayError, setBirthdayError] = useState<string | null>(null);

    const isValidBirthday = (date: moment.Moment | null): string | null => {
        if (!date || !date.isValid()) {
            return 'Ungültiges Datum';
        }

        if (date.isAfter(moment())) {
            return 'Geburtsdatum liegt in der Zukunft';
        }

        const age = moment().diff(date, 'years');
        if (age < 18) {
            return 'Du musst mindestens 18 Jahre alt sein';
        }

        return null; // alles ok
    };


    const handleBirthdayChange = (date: moment.Moment | null) => {
        const error = isValidBirthday(date);
        setBirthdayError(error);

        const value = date && date.isValid() ? date.format('YYYY-MM-DD') : '';
        const event = {
            target: {
                value,
                name: 'birthday',
            },
        } as React.ChangeEvent<HTMLInputElement>;
        handleInputChange('birthday')(event);
    };


    const validateForm = useCallback(() => {
        const person = localUserData.person;
        return (person?.firstname || '') !== '' &&
                (person?.lastname || '') !== '' &&
                (person?.email || '') !== '' &&
                (person?.birthday || '') !== '' &&
                (person?.gender || '') !== '';
    }, [localUserData]);

// Modify your useEffect hook like this
    useEffect(() => {
        disableNext(!validateForm());
    }, [disableNext, localUserData, validateForm]);



    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <TextField
                        label="Vorname"
                        margin="dense"
                        sx={profileStyling.textfield}
                        onChange={handleInputChange('firstname')}
                        value={localUserData.person!.firstname}
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <TextField
                        label="Nachname"
                        margin="dense"
                        sx={profileStyling.textfield}
                        onChange={handleInputChange('lastname')}
                        value={localUserData.person!.lastname}
                        required
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        label="Email"
                        margin="dense"
                        sx={profileStyling.textfield}
                        value={localUserData.person!.email}
                        required disabled />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <TextField
                        label="Telefon"
                        margin="dense"

                        sx={profileStyling.textfield}
                        onChange={handleInputChange('phone')}
                        value={localUserData.person!.phone}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={4}>
                    <FormControl margin="dense" fullWidth required>
                        <DatePickerVanlife
                            handleChange={handleBirthdayChange}
                            value={localUserData.person!.birthday ? moment(localUserData.person!.birthday, 'YYYY-MM-DD') : null}
                            label="Geburtsdatum *"
                            name="birthday"
                            error={!!birthdayError}
                            helperText={birthdayError ?? ''}
                        />
                    </FormControl>


                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <FormControl margin="dense" fullWidth required>
                        <InputLabel>Geschlecht</InputLabel>
                        <Select
                            label="Geschlecht"
                            sx={profileStyling.textfield}
                            onChange={(event) => {
                                if (localUserData.person) {
                                    setLocalUserData({
                                        ...localUserData,
                                        person: {...localUserData.person, gender: event.target.value as Person.gender}
                                    });
                                }
                            }}
                            value={localUserData.person!.gender || ''}
                            required
                        >
                            <MenuItem value=''>Bitte Geschlecht auswählen</MenuItem>
                            <MenuItem value={Person.gender.MALE}>Männlich</MenuItem>
                            <MenuItem value={Person.gender.FEMALE}>Weiblich</MenuItem>
                            <MenuItem value={Person.gender.OTHER}>Andere</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <TextField
                        label="Community Name"
                        helperText="Wird unter anderem bei Bewertungen angezeigt."
                        margin="dense"
                        sx={profileStyling.textfield}
                        value={localUserData.communityName}
                        onChange={(event) => { setLocalUserData({...localUserData, communityName: event.target.value}) }}
                    />
                </Grid>
            </Grid>

        </Box>

    );
};

export default RegisterPersonalInformationForm;