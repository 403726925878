/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type Availability = {
    availabilityId?: number;
    offerId?: number;
    datetime?: number;
    availabilityStatus?: Availability.availabilityStatus;
    price?: number;
    tourId?: number;
    comment?: string;
    booked?: boolean;
    reserved?: boolean;
    available?: boolean;
};
export namespace Availability {
    export enum availabilityStatus {
        AVAILABLE = 'AVAILABLE',
        RESERVED = 'RESERVED',
        BOOKED = 'BOOKED',
        BOOKED_BY_HOST = 'BOOKED_BY_HOST',
    }
}

