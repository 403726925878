import React from 'react';
import { Card, CardContent, Typography, useTheme } from '@mui/material';
import Button from "@mui/material/Button";
import {chargebeePromise} from "../../../utils/chargebee";
import {CancelablePromise, SubscriptionService} from "../../../services/restserver-openapi";
import {getUserAsync, getUserEntitlementsAsync, getUserSubscriptionsAsync} from "../../../store/navBar/userSlice";
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import Box from "@mui/material/Box";


// async function BuyMugOneTime() {
//     const cbInstance = await chargebeePromise;
//
//     if (!cbInstance) {
//         console.error('Chargebee not loaded');
//         return;
//     }
//
//     const hostedPageData = await cbInstance.getHostedPage(() => ({
//         type: 'checkout_new',
//         items: [{ item_price_id: 'vanlifer-early-access-CHF-Yearly', quantity: 1 }],
//     }));
//
//     console.log('Hosted page response:', hostedPageData);
//
//     cbInstance.openCheckout({
//         hostedPage: () => Promise.resolve(hostedPageData),
//         layout: 'in_app',
//         step: () => {},
//         success: (page) => console.log('Success:', page),
//         close: () => console.log('Closed'),
//     });
// }




interface ImpactCardProps {
    hasEarlyAccess: boolean;
}

const EarlyAccessStateCard: React.FC<ImpactCardProps> = ({ hasEarlyAccess }) => {

    const theme = useTheme();
    const dispatch = useAppDispatch();
    const userRole = useAppSelector(state => state.user.userRole);
    const userSpecificationId = useAppSelector(state => state.user.userSpecificationId);

    async function openSelfServicePortal() {
        const cbInstance = await chargebeePromise;

        if (!cbInstance) {
            console.error('Chargebee not loaded');
            return;
        }

        const hostedPagePromise : CancelablePromise<any> = SubscriptionService.requestSubscriptionIntent('vanlifer-early-access-CHF-Yearly', true);

        cbInstance.openCheckout({
            hostedPage: function() {
                return hostedPagePromise;
            },
            success: function() {
                dispatch(getUserSubscriptionsAsync())
            }, close(): void {
            }, layout: "in_app", step(currentStep: string): void {
            }
        });
    }

    return (
        <Card
            sx={{
                padding: 4,
                boxShadow: 6,
                borderRadius: 3,
                background: 'linear-gradient(135deg, #1f3a34 0%, #1B2934 100%)',
                color: '#CCDA10',
            }}
        >
            <CardContent>
                {hasEarlyAccess ? (
                    <>
                        <Typography variant="h5" fontWeight={700} gutterBottom>
                            Du hast bereits <span style={{ color: 'white' }}>Early Access</span>
                        </Typography>
                        <Typography variant="body1">
                            Danke, dass die Vanlife Travel Idee mit einem Early Access unterstützt! Dein Feedback ist Gold wert – es hilft uns, die Plattform in die richtige Richtung zu entwickeln.
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography variant="h5" fontWeight={700} gutterBottom>
                            Hast Du Lust unsere Idee zu unterstützen?
                        </Typography>
                        <Typography variant="body1">
                            Mit unserem <span style={{color: 'white', fontWeight: 700}}>Early Access</span> bekommst du exklusive
                            Einblicke und immer die
                            neuesten Features. Ausserdem kannst Du uns jederzeit Feedback geben und hast Einfluss auf
                            die Weiterentwicklung. Unterstütze die Idee von Vanlife Travel zum Preis von einem Kaffee
                            pro Monat.
                        </Typography>
                        <Box mt={4} display="flex" justifyContent="center">
                            <Button variant="contained" color="secondary" onClick={openSelfServicePortal}>
                                Early Access Mitglied werden
                            </Button>
                        </Box>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default EarlyAccessStateCard;
