import React from 'react';
import Typography from '@mui/material/Typography';
import {User} from "../../services/restserver-openapi";
import BookingOverview from "../vanliferComponents/BookingOverview";
import {Box} from "@mui/material";

interface VanliferDetailsProps {
    user: User;
    vanliferId: number;

}

const VanliferDetails: React.FC<VanliferDetailsProps> = ({ user, vanliferId }) => {
    return (
        <Box>
            <BookingOverview userSpecificationId={vanliferId} display={'compact'} />
        </Box>
    );
};

export default VanliferDetails;