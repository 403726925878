import Container from '@mui/material/Container';
import * as React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Compound, OfferCompoundService } from '../services/restserver-openapi';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { CompoundImages } from '../components/campsiteDetailComponents/CompoundImages';
import { CompoundReviews } from '../components/campsiteDetailComponents/CompoundReviews';
import { CompoundDetailCard } from '../components/campsiteDetailComponents/CompoundDetailCard';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ConstructionIcon from '@mui/icons-material/Construction';
import EmojiPeopleOutlinedIcon from '@mui/icons-material/EmojiPeopleOutlined';
import { CompoundEquipment } from '../components/campsiteDetailComponents/CompoundEquipment';
import { countryCodes as countries } from '../constants/country_code';
import Stack from '@mui/material/Stack';
import PlaceIcon from '@mui/icons-material/Place';
import Reviews from '@mui/icons-material/Reviews';
import { EcoFriendlyIcon } from '../components/vanliferComponents/EcoFriendlyIcon';
import Grid from '@mui/material/Grid';
import ExploreIcon from '@mui/icons-material/Explore';
import DirectionsIcon from '@mui/icons-material/Directions';
import Rating from '@mui/material/Rating';
import { PaletteMode, Skeleton } from '@mui/material';
import Button from '@mui/material/Button';
import {  useAppSelector } from '../hooks/hooks';
import HereMapWithMarker from '../components/presentationalComponents/HereMapWithMarker';
import SitesListing from '../components/presentationalComponents/SitesListing';
import Main from '../layouts/Main';
import { alpha, useTheme } from '@mui/material/styles';
import RvHookupIcon from '@mui/icons-material/RvHookup';
import {getReadableCompoundType} from "../utils/typeUtils";
import OfferImages from "../components/presentationalComponents/OfferImages";

export function CampsiteDetailPage(): React.ReactElement {
  const theme = useTheme();
  const mode = theme.palette.mode;

  const navigate = useNavigate();
  // const generalStyling = createGeneralStyling(theme);
  const isVerifiedAgent = useAppSelector(state => state.user.isVerifiedAgent);

  const { campsiteId } = useParams();

  const vanliferComponentState = useAppSelector(state => state.vanliferComponents);

  const [compound, setCompound] = React.useState<Compound | null>(null);
  const [rating, setRating] = React.useState<number | null>(null);

  useEffect(() => {
    if (campsiteId === undefined) {
      return;
    }

    OfferCompoundService.getCompound(parseInt(campsiteId)).then(c => setCompound(c));
  }, [campsiteId]);

  function backButton() {
    if (!vanliferComponentState.showEditorComponent &&
      !vanliferComponentState.showPaymentComponent &&
      !vanliferComponentState.showBookedComponent) {
      navigate(-1);
    } else {
      navigate('/');
    }
  }

  const AverageRating = () => {
    if (compound === null) {
      return <Skeleton variant="rectangular" width={185} height={20} />;
    }

    if (!rating) {
      return null;
    }

    return (
      <Stack direction="row" spacing={1} sx={{ display: 'flex' }}>
        <Typography>Ø</Typography>
        <Rating value={rating} precision={0.1} readOnly />
        <Typography>({rating.toFixed(1)})</Typography>
      </Stack>
    );
  };

  const EcoFriendly = () => {
    if (compound?.sustainable) {
      return (
        <Typography sx={{ color: '#13ab1a' }}>
          {/* Text here */}
          <EcoFriendlyIcon sx={{ ml: 1, position: 'relative', top: '6px' }} />
          Eco
        </Typography>
      );
    }

    return null;
  };

  const isLoading = compound === null;
  let details: React.ReactNode;
  let title: React.ReactNode;

  if (isLoading) {
    title = <Skeleton variant="rounded" width={500} height={50} />;
    details = (
      <>
        <Skeleton variant="rounded" width="100%" height={160} />
        <Skeleton variant="rounded" width="100%" height={120} />
        <Skeleton variant="rounded" width="100%" height={95} />
      </>
    );
  } else {
    title = <>{compound.name}</>;
    details = (
      <>
        <CompoundDetailCard icon={<PlaceIcon />} title="Adresse">
          <Typography>{compound.addressLine1}</Typography>
          <Typography>{compound.zipCode} {compound.city}</Typography>
          <Typography>{countries.find((country) => country.countryCode === compound.country)?.countryName}</Typography>
        </CompoundDetailCard>

        <CompoundDetailCard icon={<ExploreIcon />} title="Standort">
          <Typography variant={'body1'}
                      color={'text.secondary'}>{compound.coordinates?.x}, {compound.coordinates?.y}</Typography>
          {compound.coordinates?.x && compound.coordinates?.y &&
            <HereMapWithMarker mapMarker={{ latitude: compound.coordinates.x, longitude: compound.coordinates.y }}
                               width="100%" height="300px" />}
        </CompoundDetailCard>


      </>
    );
  }

  return (
    <Main>
      <Box bgcolor={theme.palette.alternate} marginBottom={2}>
        <Container>
          <Button variant={'contained'} color={'primary'} onClick={backButton}
                  sx={{ marginTop: '2rem', marginBottom: '2rem' }}>Zurück</Button>
        </Container>
      </Box>

      <Box bgcolor={theme.palette.alternate} marginBottom={2}>
        <Container>
          <Grid container spacing={4}>
            <Grid item container xs={12} md={12} alignItems={'center'}>
              <Box>
                <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                  <AverageRating />
                </Stack>

                <Stack direction="row" sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                <Box >
                  <Typography
                    variant="h3"
                    color="text.primary"
                    sx={{ fontWeight: 700 }}
                  >
                    <Typography
                      color={'text.primary'}
                      component={'span'}
                      variant={'inherit'}
                      sx={{
                        background: `linear-gradient(180deg, transparent 82%, ${alpha(
                          mode === 'light' as PaletteMode ? theme.palette.secondary.main : theme.palette.primary.main,
                          0.3,
                        )} 0%)`,
                      }}
                    >
                      {title}
                    </Typography>
                  </Typography>
                </Box>
                <EcoFriendly />
              </Stack>

                <Box marginBottom={3}>
                  <Typography variant="h6" component="p" color="text.secondary">
                    Alle Infos mit Bildern und Reviews
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', sm: 'row' }}
                  alignItems={{ xs: 'stretched', sm: 'flex-start' }}
                >
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/*<Divider sx={{marginTop:0, marginBottom:0}}/>*/}

      <Box bgcolor={theme.palette.alternate} marginBottom={2}>
        <Container>




          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                {details}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={2}>
                <CompoundDetailCard icon={<PhotoLibraryIcon />} title="Bilder">
                  <CompoundImages compound={compound} />
                </CompoundDetailCard>
              </Stack>
            </Grid>
          </Grid>

        </Container>
      </Box>

      <Box bgcolor={theme.palette.alternate} marginBottom={2}>
        <Container>
          <Grid container spacing={4}>
            <Grid item container xs={12} alignItems={'center'}>
              <CompoundDetailCard icon={<EmojiPeopleOutlinedIcon />} title="Ihr Gastgeber stellt sich vor...">
                <Typography>{compound?.guestInformation}</Typography>
              </CompoundDetailCard>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box bgcolor={theme.palette.alternate} marginBottom={2}>
        <Container>
          <Grid container spacing={4}>
            <Grid item container xs={12} alignItems={'center'}>
              <CompoundDetailCard icon={<ConstructionIcon />} title="Ausstattung">
                <CompoundEquipment compound={compound} layout="text" largeIcons={false} showTextIfNoEquipment={true} />
              </CompoundDetailCard>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Box bgcolor={theme.palette.alternate} marginBottom={2}>
        <Container>
          <CompoundDetailCard icon={<Reviews />} title="Reiseerlebnisse">
          <CompoundReviews compoundId={compound?.compoundId} setAverageRating={setRating} compound={compound!} />
          </CompoundDetailCard>
        </Container>
      </Box>

      {isVerifiedAgent &&
          <Box bgcolor={theme.palette.alternate} marginBottom={2}>
            <Container>
            <CompoundDetailCard icon={<RvHookupIcon />} title="Stellplätze">
              <Box>
                {compound?.compoundId && <SitesListing compoundId={compound.compoundId} />}
              </Box>
            </CompoundDetailCard>
            </Container>
          </Box>
      }
      <Box bgcolor={theme.palette.alternate} marginBottom={2}>
        <Container>
          <Button variant={'contained'} color={'primary'} onClick={backButton}
                  sx={{ marginBottom: '2rem' }}>Zurück</Button>
        </Container>
      </Box>
    </Main>
  );
}
