import React from 'react';
import { Box, Card, Container, Grid, Typography } from '@mui/material';
import YouTubeCard from "../../presentationalComponents/YouTubeCard";


const CommunityChannels = () => {
    const channels = [
        {
            name: "Flavour Trip",
            avatar: "https://yt3.googleusercontent.com/by9Fh1prHd_mmc52XM3nscTy2U7qssX0D3sE4w2e6OQAIlEz3wE3MRFImIk9L-xoWtSSYmgkQg=s160-c-k-c0x00ffffff-no-rj",
            banner: "https://yt3.googleusercontent.com/IAq7iCothn4ZWPz3zBQqzUagPNDpcaDhMLKmVL_Rzs6CA8Eyh4FHhFvGM1ovpHZVqZ9BbzZx9Q=w1707-fcrop64=1,00005a57ffffa5a8-k-c0xffffffff-no-nd-rj",
            link: "https://youtube.com/@flavourtrip",
            description: "Tolle Musik, die sofort Lust aufs nächste Vanlife Abenteuer macht!",
        },
        {
            name: "TravelCampingLiving",
            avatar: "https://yt3.googleusercontent.com/ytc/AIdro_nlH-_ir7EKcdZ0xOqEyf4uVmFlk45kSv-ryTQK1rgrXg=s160-c-k-c0x00ffffff-no-rj",
            banner: "https://yt3.googleusercontent.com/7MTSAlN8KERbnSwiByuE-6qqrpYzhMH2SAL45ks2VWu0b83JWdKtTUnehVpRrL5-GjSroxG_fw=w1707-fcrop64=1,00005a57ffffa5a8-k-c0xffffffff-no-nd-rj",
            link: "https://youtube.com/@TravelCampingLiving",
            description: "Begleite Kai und seinen Van Zottl auf Ihren Abenteuern!",
        },
    ];

    return (
        <Card
            sx={{
                mt: 5,
                p: 3,
                boxShadow: 3,
                backgroundColor: "#eef2b6",
            }}
        >
            <Typography variant="h4" fontWeight={600} align="center" gutterBottom>
                Unsere Favoriten auf YouTube
            </Typography>
            <Typography
                variant="body1"
                color="text.secondary"
                align="center"
                paragraph
            >
                Wir haben tolle Kanäle, die wir regelmäßig ansehen.
            </Typography>

            <Grid container spacing={4} justifyContent="center">
                {channels.map((channel, index) => (
                    <Grid item key={index} xs={12} sm={6} md={3}>
                        <YouTubeCard
                            type="channel"
                            title=""
                            description={channel.description}
                            channelName={channel.name}
                            channelUrl={channel.link}
                            channelAvatarUrl={channel.avatar || undefined}
                            channelBannerUrl={channel.banner || undefined}
                        />
                    </Grid>
                ))}
            </Grid>
        </Card>
    );
};

export default CommunityChannels;