import {
    Box,
    Divider,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Paper,
    Button
} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useAppDispatch} from "../../hooks/hooks";
import {fetchToursFromUser} from "../../store/Vanlifer/tourSlice";
import {TourOverview} from "../../services/restserver-openapi";
import BookingOverviewGridComponent, {BookingOverviewSkeleton} from "./BookingOverviewGridComponent";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ScheduleIcon from '@mui/icons-material/Schedule';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {Tooltip} from '@mui/material';
import {Link as RouterLink} from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';

interface BookingOverviewProps {
    userSpecificationId: number;
    display?: 'compact' | 'detailed';
}

const getStatusIcon = (tour: TourOverview) => {
    if (tour.status === 'CANCELED') {
        return (
            <Tooltip title="Storniert">
                <CancelIcon color="error"/>
            </Tooltip>
        );
    }

    if (tour.tourCompleted) {
        return (
            <Tooltip title="Abgeschlossen">
                <CheckCircleIcon color="success"/>
            </Tooltip>
        );
    }

    if (tour.status === 'BOOKED') {
        return (
            <Tooltip title="Geplant">
                <ScheduleIcon color="primary"/>
            </Tooltip>
        );
    }

    return (
        <Tooltip title="Unbekannter Status">
            <HelpOutlineIcon color="disabled"/>
        </Tooltip>
    );
};

function BookingOverview({userSpecificationId, display = 'detailed'}: BookingOverviewProps) {
    const dispatch = useAppDispatch();

    const [futureTours, setFutureToursData] = useState<TourOverview[] | null>(null);
    const [pastTours, setPastToursData] = useState<TourOverview[] | null>(null);
    const [cancelTours, setCancelToursData] = useState<TourOverview[] | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const getFirstStartDate = useCallback((tour: TourOverview): number | undefined => {
        const stays = tour.stays;
        if (stays && stays.length > 0) {
            const sortedStays = stays.slice().sort((a, b) => (a.fromDate || 0) - (b.fromDate || 0));
            return sortedStays[0]?.fromDate;
        }
        return undefined;
    }, []);

    const getLastEndDate = useCallback((tour: TourOverview): number | undefined => {
        const stays = tour.stays;
        if (stays && stays.length > 0) {
            const sortedStays = stays.slice().sort((a, b) => (b.toDate || 0) - (a.toDate || 0));
            return sortedStays[0]?.toDate;
        }
        return undefined;
    }, []);

    const orderByFirstStartDate = useCallback((tours: TourOverview[]): TourOverview[] => {
        return tours.slice().sort((a, b) => {
            const dateA = getFirstStartDate(a);
            const dateB = getFirstStartDate(b);
            if (dateA === undefined && dateB === undefined) return 0;
            if (dateA === undefined) return 1;
            if (dateB === undefined) return -1;
            return dateA - dateB;
        });
    }, [getFirstStartDate]);

    const fetchTours = useCallback(async () => {
        setIsLoading(true);
        try {
            const [booked, cancelled] = await Promise.all([
                dispatch(fetchToursFromUser({vanliferId: userSpecificationId, tourStatus: 'BOOKED'})).unwrap(),
                dispatch(fetchToursFromUser({vanliferId: userSpecificationId, tourStatus: 'CANCELED'})).unwrap()
            ]);

            if (booked) {
                const uncompleted = booked.filter(t => !t.tourCompleted);
                const completed = booked.filter(t => t.tourCompleted);
                setFutureToursData(orderByFirstStartDate(uncompleted));
                setPastToursData(orderByFirstStartDate(completed));
            }

            if (cancelled) {
                setCancelToursData(orderByFirstStartDate(cancelled));
            }
        } catch (error) {
            setIsError(true);
        }
        setIsLoading(false);
    }, [dispatch, orderByFirstStartDate, userSpecificationId]);

    useEffect(() => {
        fetchTours();
    }, [fetchTours]);

    const renderTours = (tours: TourOverview[] | null, textIfEmpty: string) => {
        if (isLoading) return <BookingOverviewSkeleton/>;
        if (isError) return <Typography color="error">Fehler beim Laden.</Typography>;
        if (!tours || tours.length === 0) return <Typography>{textIfEmpty}</Typography>;
        return <BookingOverviewGridComponent tours={tours}/>;
    };

    const allTours = [...(futureTours || []), ...(pastTours || []), ...(cancelTours || [])];
    const sortedAllTours = orderByFirstStartDate(allTours);

    const renderCompactTable = () => {
        if (isLoading) return <BookingOverviewSkeleton/>;
        if (isError) return <Typography color="error">Fehler beim Laden.</Typography>;
        if (!sortedAllTours || sortedAllTours.length === 0) return <Typography>Keine Reisen vorhanden.</Typography>;

        return (
            <Paper sx={{padding: 4, borderRadius: 5, width: '100%', mt: '.1rem'}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><Typography
                                color={'text.secondary'}
                                variant={'caption'}
                                fontWeight={700}
                                sx={{textTransform: 'uppercase'}}
                            >Tourname</Typography></TableCell>
                            <TableCell><Typography
                                color={'text.secondary'}
                                variant={'caption'}
                                fontWeight={700}
                                sx={{textTransform: 'uppercase'}}
                            >Class</Typography></TableCell>
                            <TableCell><Typography
                                color={'text.secondary'}
                                variant={'caption'}
                                fontWeight={700}
                                sx={{textTransform: 'uppercase'}}
                            >Rating</Typography></TableCell>
                            <TableCell><Typography
                                color={'text.secondary'}
                                variant={'caption'}
                                fontWeight={700}
                                sx={{textTransform: 'uppercase'}}
                            >Status</Typography></TableCell>
                            <TableCell><Typography
                                color={'text.secondary'}
                                variant={'caption'}
                                fontWeight={700}
                                sx={{textTransform: 'uppercase'}}
                            >Startdatum</Typography></TableCell>
                            <TableCell><Typography
                                color={'text.secondary'}
                                variant={'caption'}
                                fontWeight={700}
                                sx={{textTransform: 'uppercase'}}
                            >Enddatum</Typography></TableCell>
                            <TableCell><Typography
                                color={'text.secondary'}
                                variant={'caption'}
                                fontWeight={700}
                                sx={{textTransform: 'uppercase'}}
                            >Details</Typography></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedAllTours.map((tour) => {
                            const startDate = getFirstStartDate(tour);
                            const endDate = getLastEndDate(tour);
                            return (
                                <TableRow key={tour.tourId}>
                                    <TableCell>{tour.name}</TableCell>
                                    <TableCell>{tour.tourclass}</TableCell>
                                    <TableCell>{tour.rating}</TableCell>
                                    <TableCell>
                                        <Box display="flex" alignItems="center" gap={1}>
                                            {getStatusIcon(tour)}
                                            {tour.tourCompleted
                                                ? 'Abgeschlossen'
                                                : tour.status === 'CANCELED'
                                                    ? 'Storniert'
                                                    : 'Geplant'}
                                        </Box>
                                    </TableCell>
                                    <TableCell>{startDate ? new Date(startDate).toLocaleDateString() : 'Unbekannt'}</TableCell>
                                    <TableCell>{endDate ? new Date(endDate).toLocaleDateString() : 'Unbekannt'}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="outlined"
                                            component={RouterLink}
                                            to={`/vanlifer/Tour/${tour.tourId}`}
                                            startIcon={<InfoIcon/>}
                                        >
                                            Details
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Paper>
        );
    };

    if (display === 'compact') {
        return <Box>{renderCompactTable()}</Box>;
    }

    return (
        <Box>
            <Typography variant="caption" color="text.secondary" fontWeight={700} sx={{textTransform: 'uppercase'}}>Bevorstehende
                Reisen:</Typography>
            {renderTours(futureTours, "Keine Reisen geplant.")}
            <Divider sx={{my: 2}}/>

            <Typography variant="caption" color="text.secondary" fontWeight={700} sx={{textTransform: 'uppercase'}}>Vergangene
                Reisen:</Typography>
            {renderTours(pastTours, "Keine Reisen durchgeführt.")}
            <Divider sx={{my: 2}}/>

            <Typography variant="caption" color="text.secondary" fontWeight={700} sx={{textTransform: 'uppercase'}}>Stornierte
                Reisen:</Typography>
            {renderTours(cancelTours, "Keine Reisen abgebrochen / gelöscht.")}
        </Box>
    );
}

export default BookingOverview;
