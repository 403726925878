import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Accordion, AccordionDetails,
    AccordionSummary, Box, Card, CardContent, CardMedia, CircularProgress, Divider, Grid, Link, Typography,
    useTheme, } from "@mui/material";
import { IconButton, Dialog, DialogTitle, DialogContent, Tooltip } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"; // oder Autoren-Icon deiner Wahl

import Button from '@mui/material/Button';
import SVGComponent from '../presentationalComponents/SVGComponent';
import {Review, StayDetail} from "../../services/restserver-openapi";
import moment from "moment";
import {EditCalendar, Hotel} from '@mui/icons-material';
import {TimelineConnector, TimelineContent, TimelineItem, TimelineSeparator} from "@mui/lab";
import AlternativeSitesComponent from "./AlternativeSitesComponent";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import LoadingPage from "../presentationalComponents/LoadingPage";
import EditStayCalender from "./EditStayCalendar/EditStayCalender";
import {asyncChangeStayDuration} from "../../store/Vanlifer/tourSlice";
import VltAlertDialog from '../presentationalComponents/VltAlertDialog';
import {vltAlertDialog} from "../../constants/textConstant";
import {ReviewModal} from "./Rating/ReviewModal";
import {SiteRating} from "./Rating/SiteRating";
import {OfferReviewService, RatingAndComment} from "../../services/restserver-openapi";
import { useNavigate } from 'react-router-dom';
import { CompoundEquipment } from '../campsiteDetailComponents/CompoundEquipment';
import {createGeneralStyling} from "../../theme/generalStyling";
import {getReadableSiteType, getReadableCompoundType} from "../../utils/typeUtils";
import Rating from "@mui/material/Rating";
import Stack from '@mui/material/Stack';

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HotelIcon from "@mui/icons-material/Hotel";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import ImageIcon from "@mui/icons-material/Image";
import {EcoFriendlyIcon} from './EcoFriendlyIcon';
import useMediaQuery from "@mui/material/useMediaQuery";

interface SingleSiteProposalCardProps {
    stay: StayDetail;
    tourDistance: number;
    disableEditing: boolean;
}

const SingleSiteProposalCard: React.FC<SingleSiteProposalCardProps> = ({ stay, tourDistance, disableEditing }) => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    let navigate = useNavigate();
    const tourId = useAppSelector(state => state.tour.choosedTourDetail.tourId);


    // State für Kalender und Datumsauswahl
    const [showAlternatives, setShowAlternatives] = useState<boolean>(false);

    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [newFromDate, setNewFromDate] = useState<number>(0);
    const [newToDate, setNewToDate] = useState<number>(0);
    const [isEditingCheckin, setIsEditingCheckin] = useState<boolean>(false);
    const [isEditingCheckout, setIsEditingCheckout] = useState<boolean>(false);
    const [showStayCalendar, setShowStayCalendar] = useState<boolean>(false);

    const calendarRef = useRef<HTMLDivElement>(null);
    const checkinButtonRef = useRef<HTMLButtonElement>(null);
    const checkoutButtonRef = useRef<HTMLButtonElement>(null);

    // Benutzerrechte prüfen
    const userEntitlements = useAppSelector(state => state.user.entitlements);

    const canUseEditor = useMemo(() => {
        return userEntitlements.some(e => e.featureId === 'vanliferuseeditor' && e.value === "true");
    }, [userEntitlements]);

    const canWriteReviews = useMemo(() => {
        return userEntitlements.some(e => e.featureId === 'vanliferwritereview' && e.value === "true");
    }, [userEntitlements]);

    // Datum speichern
    const saveDateChanges = (from: number, to: number): void => {
        // console.log('New dates:', moment(from).format('DD.MM.YYYY'), '-', moment(to).format('DD.MM.YYYY'));

        if (stay.stayId && tourId) {
            dispatch(asyncChangeStayDuration({
                tourId,
                stayId: stay.stayId,
                newFromDate: from,
                newToDate: to
            }));
        }

        // setIsEditingCheckin(false);
        // setIsEditingCheckout(false);
        setShowStayCalendar(false);
    };

    const handleEditedStayDates = (from: number, to: number): void => {
        setNewFromDate(from);
        setNewToDate(to);
        if (from === to) setOpenDialog(true);
        else saveDateChanges(from, to);
    };

    // Checkin-/Checkout-Buttons toggeln
    // const handleClickOnCheckinDate = (): void => {
    //     setIsEditingCheckin(!isEditingCheckin);
    //     setIsEditingCheckout(false);
    //     setShowStayCalendar(true);
    // };
    //
    // const handleClickOnCheckoutDate = (): void => {
    //     setIsEditingCheckout(!isEditingCheckout);
    //     setIsEditingCheckin(false);
    //     setShowStayCalendar(true);
    // };


    // Bildanzeige
    const CompoundImage = (): JSX.Element => {
        if (stay.stayCompound?.imageIds?.length && stay.stayCompound.imageIds.length > 0) {
            return (
                <CardMedia
                    component="img"
                    src={`${process.env.REACT_APP_API_URL}/image/${stay.stayCompound.imageIds[0]}`}
                    alt="Vanlife"
                    sx={{ width: '100%', height: 200, borderRadius: 2 }}
                />
            );
        }
        return (
            <Box
                sx={{
                    width: '100%', height: 200,
                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                    bgcolor: 'grey.100', borderRadius: 2
                }}>
                <ImageIcon fontSize="large" color="disabled" />
            </Box>
        );
    };

    return (
        <React.Fragment>
            <Accordion
                disableGutters
                elevation={0}
                sx={{
                    border: `1px solid ${theme.palette.divider}`,
                    mb: 2,
                    '&::before': { display: 'none' }
                }}
            >
                {/* Kompakte Kopfzeile */}
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{color: 'primary.main'}} />}
                    sx={{
                        borderRadius: 2,
                        bgcolor: 'primary.main',
                        color: 'secondary.main',
                        px: 2, py: 1
                    }}
                >
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={6} md={3}>
                            <Typography variant="body2">
                                {moment(stay.fromDate).format('DD.MM.YYYY')} – {moment(stay.toDate).format('DD.MM.YYYY')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box display="flex" alignItems="center">
                                <HotelIcon fontSize="small" sx={{ mr: 1 }} />
                                <Link
                                    underline="hover"
                                    color="inherit"
                                    onClick={() => {
                                        if (stay.stayCompound?.compoundId) {
                                            navigate(`/vanlifer/Campsite/${stay.stayCompound.compoundId}`);
                                        }
                                    }}
                                    sx={{ fontWeight: 700, cursor: 'pointer' }}
                                >
                                    {stay.stayCompound?.name || 'Unbenanntes Camp'}
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={1}>
                            <Box display="flex" alignItems="center">
                                <Box position="relative" display="inline-flex">
                                    <CircularProgress
                                        variant="determinate"
                                        value={(stay.stayCompound?.overallRating || 0) * 10}
                                        thickness={6}
                                        size={48}
                                        color="secondary"
                                    />
                                    <Box
                                        top={0} left={0} bottom={0} right={0}
                                        position="absolute"
                                        display="flex"
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Typography variant="caption">
                                            {stay.stayCompound?.overallRating?.toFixed(1) ?? "-"}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6} md={1}>
                            {canUseEditor && !disableEditing && (
                                <Tooltip title="Alternative wählen">
                                    <IconButton
                                        color="primary"
                                        onClick={() => setShowAlternatives(true)}
                                        sx={{
                                            width: 48,
                                            height: 48,
                                            borderRadius: '50%',
                                            backgroundColor: 'primary.light',
                                            color: 'secondary',
                                            boxShadow: 1,
                                            '&:hover': {
                                                backgroundColor: 'secondary.main',
                                            }
                                        }}
                                    >
                                        <SwapHorizIcon sx={{ fontSize: 24 }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid>
                        <Grid item xs={6} md={1}>
                            {canUseEditor && !disableEditing && (
                                <Tooltip title="Daten ändern">
                                    <IconButton
                                        color="primary"
                                        onClick={() => setShowStayCalendar(prev => !prev)}
                                        sx={{
                                            width: 48,
                                            height: 48,
                                            borderRadius: '50%',
                                            backgroundColor: 'primary.light',
                                            color: 'secondary',
                                            boxShadow: 1,
                                            '&:hover': {
                                                backgroundColor: 'secondary.main',
                                            }
                                        }}
                                    >
                                        <EditCalendarIcon sx={{ fontSize: 24 }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Grid>
                    </Grid>
                </AccordionSummary>



                {/* Detailbereich */}
                <AccordionDetails sx={{ px: { xs: 1, md: 3 }, py: 2 }}>
                    <Grid container spacing={2}>
                        {/* Bildbereich */}
                        <Grid item xs={12} md={4}>
                            <CompoundImage />
                        </Grid>

                        {/* Inhalt */}
                        <Grid item xs={12} md={8}>
                            <Typography
                                sx={{
                                    textTransform: 'uppercase',
                                    fontWeight: 'medium',
                                    fontSize: 'small',
                                }}
                                gutterBottom
                                color={'text.secondary'}
                            >
                                {getReadableCompoundType(stay.stayCompound!)}
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                {stay.stayCompound?.name}
                                {stay.stayCompound?.sustainable && <EcoFriendlyIcon sx={{ ml: 1, verticalAlign: 'middle' }} />}
                            </Typography>
                            <Typography variant="body2" gutterBottom>{stay.stayCompound?.guestInformation}</Typography>
                            <Typography variant="body2" gutterBottom>
                                {stay.stayCompound?.addressLine1}<br />
                                {stay.stayCompound?.zipCode} {stay.stayCompound?.city}, {stay.stayCompound?.country}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Koordinaten: {stay.stayCompound?.coordinates?.x}, {stay.stayCompound?.coordinates?.y}
                            </Typography>

                            <Divider sx={{ my: 2 }} />

                            {/* Datumsauswahl */}
                            {canUseEditor && !disableEditing && (
                                <Box>
                                    <Typography variant="body2" fontWeight={700}>Check-in / Check-out</Typography>
                                    <Box mt={1}>
                                        <Button
                                            variant={showStayCalendar ? "contained" : "outlined"}
                                            onClick={() => {
                                                setShowStayCalendar(prev => !prev);
                                            }}
                                            endIcon={<EditCalendarIcon />}
                                        >
                                            {moment(stay.fromDate).format('DD.MM.YYYY')} – {moment(stay.toDate).format('DD.MM.YYYY')}
                                        </Button>
                                    </Box>
                                </Box>
                            )}

                            {/* Kalender anzeigen */}
                            {showStayCalendar && (
                                <Box ref={calendarRef} mt={2}>
                                    <EditStayCalender
                                        startDateTimestamp={stay.fromDate}
                                        endDateTimestamp={stay.toDate}
                                        handleEditedStayDates={handleEditedStayDates}
                                        isStartDateFix={!isEditingCheckin}
                                        isEndDateFix={!isEditingCheckout}
                                        minDate={Date.now()}
                                        maxDate={Date.now() + 30 * 86400000}
                                    />
                                </Box>
                            )}

                            <Divider sx={{ my: 2 }} />

                            {/* Platzdetails */}
                            <Typography variant="body2" fontWeight={700} gutterBottom>
                                {getReadableSiteType(stay.staySite!)}: {stay.staySite?.label} (Größe {stay.staySite?.sizeId})
                            </Typography>
                            <Typography variant="body2">{stay.staySite?.offerDescription}</Typography>

                            <Divider sx={{ my: 2 }} />

                            {/* Ausstattung */}
                            <Typography variant="body2" fontWeight={700}>Ausstattung</Typography>
                            <CompoundEquipment compound={stay.stayCompound} site={stay.staySite} />

                            {/* Preis */}
                            <Box mt={2}>
                                <Typography variant="body2" fontWeight={700}>Preis</Typography>
                                <Typography variant="body2">
                                    {stay.price ? `${stay.price.toFixed(2)} CHF` : "Keine Preisangabe"}
                                </Typography>
                            </Box>

                            {/* Bewertungen */}
                            {canWriteReviews && (
                                <Box mt={3}>
                                    <ReviewModal stay={stay} currentUserReview={null} setCurrentUserReview={() => {}} reviewType="stay" />
                                </Box>
                            )}

                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <TimelineItem sx={{ display: 'flex', justifyContent: 'center' }} color="primary">
                <TimelineSeparator>
                    <TimelineConnector />
                    <Typography color="primary">
                        <SVGComponent iconValue="camper" />
                    </Typography>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent alignSelf="center">
                    {tourDistance && (
                        <Typography variant="h6">{tourDistance} km</Typography>
                    )}
                </TimelineContent>
            </TimelineItem>
            <Dialog open={showAlternatives} onClose={() => setShowAlternatives(false)} maxWidth="md" fullWidth>
                <DialogTitle>Alternative Stellplätze</DialogTitle>
                <DialogContent>
                    <AlternativeSitesComponent
                        alternativeSiteList={stay.alternativeSites}
                        stayId={stay.stayId!}
                    />
                </DialogContent>
            </Dialog>

        </React.Fragment>

    );
};

export default SingleSiteProposalCard;