import React from 'react';
import { Box, Card, Container, Grid, Typography } from '@mui/material';
import crownImage from '../../../assets/Images/sticker/sticker_crown.png'

const SupporterWallOfFame = () => {
    const supporters = [
        { name: 'Supporter 1', logo: '/images/supporter2.png', link: 'https://supporter1.com', code: 'EARLYBIRD' },
        { name: 'Supporter 2', logo: '/images/supporter2.png', link: 'https://supporter2.com', code: 'DISCOUNT20' },
        { name: 'Supporter 3', logo: '/images/supporter3.png', link: 'https://supporter3.com', code: '' },
        { name: 'Supporter 4', logo: '/images/supporter4.png', link: 'https://supporter4.com', code: 'WELCOME10' },
        { name: 'Supporter 5', logo: '/images/supporter5.png', link: 'https://supporter5.com', code: '' },
        { name: 'Supporter 6', logo: '/images/supporter6.png', link: 'https://supporter6.com', code: 'SAVE15' },
    ];

    return (
        <Card sx={{ mt:5, padding: 3, boxShadow: 3, backgroundColor:'#eef2b6' }}>
            <Box
                component="img"
                src={crownImage.toString()}
                alt="Krone"
                sx={{
                    position: 'relative',
                    top: -10,
                    left: '50%',
                    transform: 'translateX(-50%) rotate(-10deg)',
                    width: 100,
                }}
            />
            <Typography variant="h4" fontWeight={600} align={'center'} gutterBottom>
                Supporter Wall of Fame
            </Typography>
            <Typography variant="body1" color="text.secondary" align={'center'} paragraph>
                Ein großes Dankeschön an unsere Supporter, die unsere Open Travel Community und die Weiterntwicklung der Web-App ermöglichen!
            </Typography>
            <Grid container spacing={4} justifyContent="center">
                {supporters.map((supporter, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4}>
                        <Card
                            sx={{ padding: 3, boxShadow: 3, textAlign: 'center', cursor: 'pointer', marginX: 2, borderRadius: 2 }}
                            onClick={() => supporter.link && window.open(supporter.link, '_blank')}

                        >
                            <Box component="img" src={supporter.logo.toString()} alt={supporter.name} sx={{ width: '80%', maxHeight: 100, mb: 2 }} />
                            <Typography variant="h6" fontWeight={600}>{supporter.name}</Typography>
                            {supporter.code && (
                                <Typography variant="body2" color="primary" mt={1}>
                                    Gutschein-Code: <strong>{supporter.code}</strong>
                                </Typography>
                            )}
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Card>
    );
};

export default SupporterWallOfFame;