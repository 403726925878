import {StayDetail} from "../services/restserver-openapi";
import routeMarkerPicto from '../assets/SVGIcons/Routen-Marker.svg';

export function calculateZoomLevel(waypoints: string[]) {
    let geometricWaypoints = [];
    const tmpWaypoints = waypoints;
    let zoomLevel = 1;
    let centerLat = 0;
    let centerLng = 0;

    for (let i = 0; i < tmpWaypoints.length; i++) {
        geometricWaypoints.push(tmpWaypoints[i].split(","));
    }

    if (tmpWaypoints.length > 1) {
        const minLat = Math.min(...geometricWaypoints.map((point) => Number(point[0])));
        const minLon = Math.min(...geometricWaypoints.map((point) => Number(point[1])));
        const maxLat = Math.max(...geometricWaypoints.map((point) => Number(point[0])));
        const maxLon = Math.max(...geometricWaypoints.map((point) => Number(point[1])));

        centerLat = (minLat + maxLat) / 2;
        centerLng = (minLon + maxLon) / 2;

        const distance = Math.sqrt(Math.pow(maxLat - minLat, 2) + Math.pow(maxLon - minLon, 2));
        zoomLevel = 20 - Math.ceil(Math.log(distance / 0.0005) / Math.log(2));
    } else {
        zoomLevel = 8;
        if (tmpWaypoints.length === 1) {
            centerLat = Number(geometricWaypoints[0][0]);
            centerLng = Number(geometricWaypoints[0][1]);
        }
    }
    return [zoomLevel, centerLat, centerLng];
}

export function adjustZoomLevel(zoomLevel: number, editorSite: boolean) {
    let calculateZoomLevel = 0;

    if (!editorSite) {
        calculateZoomLevel = zoomLevel - 1;
    }

    if (editorSite) {
        calculateZoomLevel = zoomLevel;
    }

    return calculateZoomLevel;
}

export function createRoutingParameter(waypoints: string[], startpoint = "", endpoint = "") {
    let waypointArray = waypoints.slice(1, -1);
    startpoint = waypoints[0];
    endpoint = waypoints[waypoints.length - 1];

    let routingParameters = undefined;

    if (waypointArray.length > 0) {
        routingParameters = {
            origin: startpoint,
            destination: endpoint,
            via: new H.service.Url.MultiValueQueryParameter(waypoints),
            routingMode: 'fast',
            transportMode: 'car',
            return: 'polyline,passthrough,summary',
            passthrough: 'true',
        };
    } else {
        routingParameters = {
            origin: startpoint,
            destination: endpoint,
            routingMode: 'fast',
            transportMode: 'car',
            return: 'polyline,summary',
        };
    }

    return routingParameters;
}

export function generateTourCoordinateList(stayList: StayDetail[]) {
    const waypointArray: string[] = [];
    for (let i = 0; i < stayList!.length; i++) {
        const XCoordinate = stayList![i].stayCompound!.coordinates!.x;
        const YCoordinate = stayList![i].stayCompound!.coordinates!.y;
        waypointArray.push(XCoordinate + "," + YCoordinate);
    }
    return waypointArray;
}

export function calculateDistance(routes: any) {
    if (routes === undefined) {
        return 0;
    }
    let distance = 0;
    if (routes.sections[0].summary) {
        for (let i = 0; i < routes.sections.length; i++) {
            distance = distance + Number(routes.sections[i].summary.length);
        }
    } else {
    }
    return Math.round((distance / 1000) * 100) / 100;
}

export function calculateDistanceList(routes: any) {
    if (routes === undefined) {
        return [];
    }
    let distanceList: number[] = [];
    for (let i = 1; i < routes.sections.length - 1; i++) {
        distanceList[i - 1] = Math.round((Number(routes.sections[i].summary.length) / 1000) * 100 / 100);
    }
    return distanceList;
}

export function addDraggableMarker(map: H.Map, behavior: H.mapevents.Behavior, lat: number, lng: number, dragEndCallback: (lat: number, lng: number) => void) {
    map.removeObjects(map.getObjects());

    const markerIcon = new H.map.Icon(routeMarkerPicto, {
        size: { w: 32, h: 32 },         // gewünschte Größe in px
        anchor: { x: 16, y: 32 }        // "Spitze" des Pins zeigt auf Koordinate
    });

    const marker = new H.map.Marker({lat:lat, lng:lng}, {
        data: undefined,
        volatility: true,
        icon: markerIcon
    });
    // Ensure that the marker can receive drag events
    marker.draggable = true;
    map.addObject(marker);

    // disable the default draggability of the underlying map
    // and calculate the offset between mouse and target's position
    // when starting to drag a marker object:
    map.addEventListener('dragstart', function(ev : any) {
        const target = ev.target,
            pointer = ev.currentPointer;
        if (target instanceof H.map.Marker) {
            let geometry = target.getGeometry();
            let targetPosition : H.math.Point | null;
            if (geometry instanceof H.geo.Point) {
                targetPosition = map.geoToScreen(geometry);
            } else {
                let firstPoint = geometry.getGeometries()[0];
                targetPosition = map.geoToScreen(firstPoint);
            }
            if (targetPosition === null) {return;}
            //@ts-ignore
            target['offset'] = new H.math.Point(pointer.viewportX - targetPosition.x, pointer.viewportY - targetPosition.y);
            behavior.disable();
        }
    }, false);


    // re-enable the default draggability of the underlying map
    // when dragging has completed
    map.addEventListener('dragend', function(ev: any) {
        var target = ev.target;
        if (target instanceof H.map.Marker) {
            let geometry = target.getGeometry();
            if (geometry instanceof H.geo.Point) {
                dragEndCallback(+geometry.lat.toFixed(5), +geometry.lng.toFixed(5));
            } else {
                let firstPoint = geometry.getGeometries()[0];
                dragEndCallback(+firstPoint.lat.toFixed(5), +firstPoint.lng.toFixed(5));
            }
            behavior.enable();
        }
    }, false);

    // Listen to the drag event and move the position of the marker
    // as necessary
    map.addEventListener('drag', function(ev:any) {
        var target = ev.target,
            pointer = ev.currentPointer;
        if (target instanceof H.map.Marker) {
            //@ts-ignore
            target.setGeometry(map.screenToGeo(pointer.viewportX - target['offset'].x, pointer.viewportY - target['offset'].y));
        }
    }, false);

    map.setCenter({lat:lat, lng:lng});
    map.setZoom(14);
}
