import React, {useCallback, useEffect, useRef} from 'react';
import "@here/maps-api-for-javascript";
import routeMarkerPicto from "../../assets/SVGIcons/Routen-Marker.svg";
import startMarkerPicto from "../../assets/SVGIcons/Start-Marker.svg";

import {
    adjustZoomLevel,
    calculateDistance,
    calculateDistanceList,
    calculateZoomLevel,
    createRoutingParameter
} from '../../utils/hereMapUtils';
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {setDetailedDistanceList, setMapCaptureFinished, setTourDistances} from "../../store/Vanlifer/tourSlice";

interface HereMapProps {
    apiKey: string;
    waypoints: string[];
    height: string;
    editorSite: boolean;
    tourId: number;
}

const HereMapTourComponent: React.FC<HereMapProps> = ({
                                                          apiKey,
                                                          waypoints,
                                                          height,
                                                          editorSite,
                                                          tourId,
                                                      }) => {
    const mapRef = useRef<HTMLDivElement>(null);
    const mapInstance = useRef<H.Map | null>(null);
    const uiInstance = useRef<H.ui.UI | null>(null);
    const showStaticMap = useAppSelector(state => state.tour.showStaticMap);
    const staticMapRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();

    const capture = useCallback((map: any, ui: any) => {
        map.capture(function (canvas: any) {
            if (!staticMapRef.current) {
                return;
            }

            if (canvas) {
                staticMapRef.current.innerHTML = '';
                staticMapRef.current.appendChild(canvas);
            } else {
                // For example when map is in Panorama mode
                staticMapRef.current.innerHTML = 'Capturing is not supported';
            }
            dispatch(setMapCaptureFinished(true))
        }, [ui]);
    }, [dispatch]);

    useEffect(() => {
        if (showStaticMap && mapInstance.current && mapRef.current) {
            capture(mapInstance.current, uiInstance.current);
        }
    }, [capture, showStaticMap]);

    useEffect(() => {
        if (waypoints !== null) {
            let map: any = undefined;
            const platform = new H.service.Platform({
                'apikey': apiKey
            });

            const defaultLayers : any = platform.createDefaultLayers();

            const routingParameters = createRoutingParameter(waypoints);

            const mapMetadata = calculateZoomLevel(waypoints);
            const optimisedZoomLevel = adjustZoomLevel(mapMetadata[0], editorSite);

            if (mapRef.current && !mapInstance.current) {
                map = new H.Map(
                    mapRef.current,
                    defaultLayers.vector.normal.map,
                    {
                        pixelRatio: window.devicePixelRatio,
                        center: {lat: mapMetadata[1], lng: mapMetadata[2]},
                        zoom: optimisedZoomLevel,
                    },
                );

                mapInstance.current = map;
                if (editorSite) {
                    uiInstance.current = H.ui.UI.createDefault(map, defaultLayers, 'de-DE');
                }

                const onResult = function (result: any) {
                    if (editorSite) {
                        const detailedDistanceList = calculateDistanceList(result.routes[0]);
                        dispatch(setDetailedDistanceList({detailedDistanceList: detailedDistanceList}));
                    }
                    const distance = calculateDistance(result.routes[0]);
                    dispatch(setTourDistances({tourId: tourId, distance: distance}));

                    if (result.routes.length) {
                        result.routes[0].sections.forEach((section: any) => {
                            let linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
                            let routeOutline = new H.map.Polyline(linestring, {
                                data: undefined,
                                style: {
                                    lineWidth: 7,
                                    strokeColor: '#1B2934',
                                    lineTailCap: 'arrow-tail',
                                    lineHeadCap: 'arrow-head'
                                }
                            });

                            let routeArrows = new H.map.Polyline(linestring, {
                                data: undefined,
                                style: {
                                    lineWidth: 7,
                                    fillColor: '#CCDA10',
                                    strokeColor: 'rgba(255, 255, 255, 1)',
                                    lineDash: [0, 3],
                                    lineTailCap: 'arrow-tail',
                                    lineHeadCap: 'arrow-head'
                                }
                            });

                            const iconSizeMap = new H.math.Size(24, 32);
                            var routenMarker_black = new H.map.Icon(routeMarkerPicto, {size: iconSizeMap});
                            var routenMarker_green = new H.map.Icon(startMarkerPicto, {size: iconSizeMap});

                            const startMarker = new H.map.Marker(section.departure.place.location, {
                                data: undefined,
                                icon: routenMarker_black,
                            });

                            const endMarker = new H.map.Marker(section.arrival.place.location, {
                                data: undefined,
                                icon: routenMarker_black,
                            });

                            map.addObjects([routeOutline, routeArrows, startMarker, endMarker])
                        });
                    }
                }

                let router = platform.getRoutingService(undefined, 8);

                router.calculateRoute(routingParameters as any, onResult,
                    function (error) {
                        alert(error.message);
                    });

                if (editorSite) {
                    new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
                }
            }
        }
    }, [apiKey, dispatch, editorSite, tourId, waypoints]);

    return (
        <React.Fragment>
            <div ref={staticMapRef} style={{
                    width: '100%',
                    height: height,
                    borderRadius: '10px',
                    visibility: showStaticMap ? 'visible' : 'hidden',
                    position: showStaticMap ? 'relative' : 'absolute',
            }}/>
            <div ref={mapRef} style={{
                width: '100%',
                height: height,
                borderRadius: '10px',
                visibility: showStaticMap ? 'hidden' : 'visible',
                position: showStaticMap ? 'absolute' : 'relative'
            }}/>
        </React.Fragment>
    );
};

export default HereMapTourComponent;
