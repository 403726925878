import React, {useState} from 'react';
import {Alert, Tab, Tabs, Typography} from '@mui/material';
import Container from '../../components/Container';
import Main from '../../layouts/Main';
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import CompoundTable from "../../components/agentComponents/CompoundTable";

const AgentCompoundOverviewPage = () => {
    const isVerifiedAgent = useAppSelector((state) => state.user.isVerifiedAgent);

    if (!isVerifiedAgent) {
        return (
            <Main>
                <Container>
                    <Alert severity="error">
                        Sie haben keine Agent-Berechtigung. Womöglich ist Ihr Account noch nicht freigeschaltet.
                    </Alert>
                </Container>
            </Main>
        );
    }

    return (
        <Main>
            <Container>
                <Typography variant="h4" gutterBottom>
                    Compound Übersicht
                </Typography>
                <CompoundTable />
            </Container>
        </Main>
    );
};

export default AgentCompoundOverviewPage;
