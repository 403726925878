import React, {useMemo} from 'react';
import {useAppSelector} from "../../hooks/hooks";
import {Avatar, Box, Grid, Link, PaletteMode, useTheme} from "@mui/material";
import Typography from "@mui/material/Typography";
import {User} from "../../services/restserver-openapi";
import { Main } from '../../layouts';
import Divider from "@mui/material/Divider";
import ProfileSettingsPage from './Page/ProfileSettingsPage';
import {paidContactInformation, freeContactInformation} from "../../constants/constants";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";

function Support() {
    const theme = useTheme();
    const mode = theme.palette.mode;
    const personId = useAppSelector((state) => state.user.user.person?.personId);
    const userId = useAppSelector((state) => state.login.userId);
    const userRoleRegister = useAppSelector((state) => state.login.userRoleLogin);
    const userSpecificationId = useAppSelector((state) => state.login.userSpecificationId);

    const roleCode = userRoleRegister === User.userRole.HOST ? "H" : userRoleRegister === User.userRole.VANLIFER ? "V" : userRoleRegister === User.userRole.AGENT ? "A" : "?";

    const userEntitlements = useAppSelector((state) => state.user.entitlements);
    const contactInformation = useMemo(() => {
        if (userEntitlements.some(entitlement => entitlement.featureId === 'showpaidsupportinformation' && entitlement.value === "true")) {
            return paidContactInformation;
        } else {
            return freeContactInformation;
        }
    }, [userEntitlements]);

    return (
        <Main>
            <ProfileSettingsPage>
                <Box>
                    <Typography variant="h6" gutterBottom fontWeight={700}>
                        Supportinformationen
                    </Typography>
                    <Typography variant={'subtitle2'} color={'text.secondary'}>
                        Wir sind für dich da! Egal, ob du Fragen hast, Unterstützung benötigst oder einfach nur mehr erfahren möchtest – unser Team hilft dir gerne weiter. Wir freuen uns über jede Anfrage und stehen dir mit Rat und Tat zur Seite. Die in Deiner Mitgliedschaft enthaltenen Kontaktmöglichkeiten findest du unten.
                    </Typography>
                    <Box
                        sx={{ my: 2 }}
                        display={'flex'}
                        flexDirection={{ xs: 'column', md: 'row' }}
                        justifyContent={'flex-start'}
                        gap={5}
                        marginBottom={4}
                    >
                        {contactInformation.map((item, i) => (
                            <Link href={item.link} target="_blank" rel="noopener noreferrer" underline="none">

                            <Box
                                key={i}
                                component={ListItem}
                                disableGutters
                                width={'auto'}
                                padding={0}
                            >
                                <Box
                                    component={ListItemAvatar}
                                    minWidth={'auto !important'}
                                    marginRight={2}
                                >
                                    <Box
                                        component={Avatar}
                                        bgcolor={mode === 'light' as PaletteMode ? theme.palette.secondary.main : theme.palette.primary.main}
                                        width={40}
                                        height={40}
                                    >
                                        {item.icon}
                                    </Box>
                                </Box>
                                    <ListItemText primary={item.label} secondary={item.value} />
                            </Box>
                            </Link>
                        ))}
                    </Box>
                </Box>
            </ProfileSettingsPage>
        </Main>
);
}

export default Support;
