import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Skeleton, Typography} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import Container from "@mui/material/Container";
import BookingTourOverviewComponent from "../../components/vanliferComponents/BookingTourOverview";
import {asyncCancelTour, loadTourDetail} from "../../store/Vanlifer/tourSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {BookingTourOverviewSkeleton} from "../../components/vanliferComponents/BookingTourOverviewSkeleton";
import Button from "@mui/material/Button";
import ScrollToBottomFab from "../../components/vanliferComponents/ScrollToBottomFab";
import Tooltip from "@mui/material/Tooltip";
import DialogContentText from "@mui/material/DialogContentText";
import TourPDFDocument from "../../components/vanliferComponents/TourPDFDocument";
import {PDFDownloadLink} from "@react-pdf/renderer";
import { pdf } from '@react-pdf/renderer';
import MonetizationBadge from "../../components/presentationalComponents/MonetizationBadge";

export default function TourPage() {
    const dispatch = useAppDispatch();
    const loading = useAppSelector(state => state.tour.loading);
    const chosenTourDetail = useAppSelector(state => state.tour.choosedTourDetail);
    const {tourId} = useParams();
    const navigate = useNavigate();
    const backButtonRef = useRef<HTMLButtonElement>(null);
    const [confirmDialogCancelOpen, setConfirmDialogCancelOpen] = useState(false);
    const userEntitlements = useAppSelector(state => state.user.entitlements);

    const canEditTour = useMemo(() => {
        return userEntitlements.some(entitlement => entitlement.featureId === 'vanliferuseeditor' && entitlement.value === "true")
    }, [userEntitlements]);

    const canCancelTour = useMemo(() => {
        return userEntitlements.some(entitlement => entitlement.featureId === 'vanlifercanceltour' && entitlement.value === "true")
    }, [userEntitlements]);

    const canExportToPDF = useMemo(() => {
        console.log(userEntitlements);
        return userEntitlements.some(entitlement => entitlement.featureId === 'vanliferpdfexport' && entitlement.value === "true")
    }, [userEntitlements]);

    const tourDatePassed = useMemo(() => {
        if (!chosenTourDetail.stays![0]) {
            return false;
        }
        const tourStartDate = new Date(chosenTourDetail.stays![0].fromDate!);
        const now = new Date();
        return now.getTime() > tourStartDate.getTime();
    }, [chosenTourDetail]);

    const within24HoursOfTourStart = useMemo(() => {
        if (!chosenTourDetail.stays![0]) {
            return false;
        }
        const tourStartDate = new Date(chosenTourDetail.stays![0].fromDate!);
        const now = new Date();
        // Check if the tour start date is within the next 24 hours
        return now.getTime() < tourStartDate.getTime() && tourStartDate.getTime() - now.getTime() <= 24 * 60 * 60 * 1000;
    }, [chosenTourDetail]);
    
    const buttonEditTourDisabled = tourDatePassed || within24HoursOfTourStart || !canEditTour || chosenTourDetail.status === 'CANCELED';
    const buttonCancelTourDisabled = tourDatePassed || within24HoursOfTourStart || !canCancelTour || chosenTourDetail.status === 'CANCELED';
    const buttonPDFExportDisabled = !canExportToPDF ;

    const [editingNotAllowed, setEditingNotAllowed] = useState(true)

    const tooltipCancelTourText = useMemo(() => {
    if (chosenTourDetail.status === 'CANCELED') {
         return "Diese Tour wurde bereits storniert";
    } else if (within24HoursOfTourStart) {
         return "Eine bald beginnende Tour kann nicht storniert werden";
    } else if (tourDatePassed) {
        return "Eine vergangene/bereits angefangene Tour kann nicht storniert werden";
    } else if (!canCancelTour) {
        return "Sie benötigen ein Abonnement um Touren zu stornieren";
    }
        return "";
    }, [chosenTourDetail.status, within24HoursOfTourStart, tourDatePassed, canCancelTour]);
    

    useEffect(() => {
        const fetchTourDetail = async () => {
            if (!tourId) {
                navigate('/Vanlifer/BookingOverview');
                return;
            }

            const id: number = parseInt(tourId);
            await dispatch(loadTourDetail(id)).unwrap();
        }

        fetchTourDetail();
    }, [dispatch, navigate, tourId]);

    let date = chosenTourDetail.statusDate ? (new Date(chosenTourDetail.statusDate)).toLocaleDateString('de-CH') : 'Keine Information';
    let price = chosenTourDetail.price ? chosenTourDetail.price.toFixed(2) + " CHF" : 'Keine Information';

    function navigateBack() {
        navigate(-1);
    }

    function handleEditTourButtonClick(){
        setEditingNotAllowed(!editingNotAllowed);
    }

    function handleOpenConfirmDialogCancel() {
        setConfirmDialogCancelOpen(true);
    }

    function handleCloseConfirmDialogCancel() {
        setConfirmDialogCancelOpen(false);
    }

    async function cancelTour() {
        setConfirmDialogCancelOpen(false);
        if (!tourId) {return;}
        const resultAction = await dispatch(asyncCancelTour(Number(tourId)));
        if (asyncCancelTour.fulfilled.match(resultAction)) {
            navigate('/Vanlifer/BookingOverview');
        }
    }

    function BookingHeaderInfos() {
        return (
            <>
                <Typography variant="h4">Buchung der Tour {chosenTourDetail.name}</Typography>
                <Typography>Gebucht am: {date}</Typography>
                <Typography>Gesamtkosten: {price} (Vor Ort bei den jeweiligen Plätzen zu bezahlen)</Typography>
            </>
        );
    }

    function PrintTourButton() {

        const handleOpenPDF = async () => {
            if (!chosenTourDetail) return;

            const blob = await pdf(<TourPDFDocument tour={chosenTourDetail} />).toBlob();
            const blobUrl = URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
        };

        if (!chosenTourDetail) return null;

        return (
            <Tooltip title="Tour als PDF im neuen Tab öffnen">
            <span>
                <Button variant="outlined" color="primary" disabled={buttonPDFExportDisabled} onClick={handleOpenPDF}>
                    Tour als PDF <MonetizationBadge/>
                </Button>
            </span>
            </Tooltip>
        );
    }

    function EditTourButton() {
        return (
          <>
          <Tooltip title={"Tour bearbeiten"}>
                        <span>
                            <Button variant="outlined" color="primary" disabled={buttonEditTourDisabled} onClick={handleEditTourButtonClick}>
                                Tour bearbeiten
                        </Button>
                        </span>
          </Tooltip>
          </>
        );
    }

    function CancelTourButton() {
        return (
            <>
            <Tooltip title={tooltipCancelTourText}>
                        <span>
                            <Button variant="outlined" color="primary" disabled={buttonCancelTourDisabled} onClick={handleOpenConfirmDialogCancel}>
                                Tour stornieren
                        </Button>
                        </span>
            </Tooltip>

        <Dialog
            open={confirmDialogCancelOpen}
            onClose={handleCloseConfirmDialogCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Tour stornieren"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Sind Sie sicher, dass Sie die Tour stornieren möchten?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseConfirmDialogCancel} color="primary">
                    Nein
                </Button>
                <Button onClick={cancelTour} color="primary" autoFocus>
                    Ja
                </Button>
            </DialogActions>
        </Dialog>
            </>
        );
    }

    return (
        <Container maxWidth={false}>
            <ScrollToBottomFab scrollToElementRef={backButtonRef}/>
            <Container sx={{margin: '14px 0px'}} maxWidth={false}>
                {!loading
                    ?
                    <Grid container display="flex" justifyContent="space-between" flexWrap="wrap">
                        <Grid item>
                            <BookingHeaderInfos />
                        </Grid>
                        <Grid item>
                            <Grid container display="flex" spacing={2} justifyContent="space-between">
                                <PrintTourButton />
                                <EditTourButton />
                                <CancelTourButton />
                            </Grid>
                        </Grid>
                    </Grid>

                    : <>
                        <Skeleton variant="text" height={60} width={500} />
                        <Skeleton variant="text" height={30} width={170} />
                        <Skeleton variant="text" height={30} width={300} />
                    </>
                }
            </Container>
            {
                !loading
                    ? <BookingTourOverviewComponent disableEditing={editingNotAllowed}></BookingTourOverviewComponent>
                    : <BookingTourOverviewSkeleton />
            }
            <Grid container ml={2} pr={5} mt={-2} display="flex" justifyContent="space-between">
                <Grid item>
                    <Button ref={backButtonRef} onClick={navigateBack} color={"primary"} variant="contained" sx={{marginBottom: '1rem'}}>Zurück</Button>
                </Grid>
            </Grid>
        </Container>
    );
}
