import React, {FormEventHandler, useState} from 'react';
import {Box, Button, Checkbox, FormControl, FormControlLabel, Grid, Typography} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {
    postPersonPreferencesAsync,
} from "../../store/navBar/userSlice";
import {welcomeRegisterPage} from "../../constants/textConstant";
import {PreferencesModel} from "../../services/dataModels/PreferencesModel";
import {Main} from "../../layouts";
import ProfileSettingsPage from "./Page/ProfileSettingsPage";
import Divider from "@mui/material/Divider";

export default function NotificationPreferencesEditor() {
    const dispatch = useAppDispatch();
    const storedPreferences = useAppSelector(state => state.user.preferences);
    const userId = useAppSelector(state => state.login.userId);
    const [localPreferences, setLocalPreferences] = useState<PreferencesModel>(storedPreferences ? storedPreferences :
        {
            "acceptedAgb": true,
            "acceptedSaveAndEdit": true,
            "acceptedNotifications": false,
        }
    )
    const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        if (userId) {
            dispatch(postPersonPreferencesAsync({userId, preferences: localPreferences}));
        }
    };

    const handleCheckboxFeature = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.name === "acceptedNotifications") {
            setLocalPreferences({ ...localPreferences, acceptedNotifications: !localPreferences.acceptedNotifications });
        }
    }

    return (
        <Main>
            <ProfileSettingsPage>
                <Box>

        <form style={{ flexGrow: '0' }} onSubmit={handleSubmit}>
            <FormControl>
                <Typography variant="h6" gutterBottom fontWeight={700}>
                    Einwilligung zur Benachrichtigung
                </Typography>
                <Typography variant={'subtitle2'} color={'text.secondary'}>
                    Die Vanlife Travel GmbH verpflichtet sich, Deine Privatsphäre zu schützen und zu respektieren. Wir verwenden Deine persönlichen Daten nur zur Verwaltung Deines Kontos und zur Bereitstellung der von Dir angeforderten Produkte und Dienstleistungen. Von Zeit zu Zeit möchten wir Dich gerne zusätzlich über unsere Produkte und Dienstleistungen sowie andere Inhalte, die für Dich von Interesse sein könnten, informieren. Wenn Du damit einverstanden bist, aktiviere bitte das folgende Kontrollkästchen.
                </Typography>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sm={12} md={12}>
                            <FormControlLabel
                                sx={{my: 2}}
                                control={<Checkbox checked={localPreferences.acceptedNotifications} onChange={handleCheckboxFeature}
                                    name="acceptedNotifications" sx={{ fontWeight: 'bold' }}></Checkbox>}
                                label={<Typography variant="body2" sx={{ fontWeight: 'bold' }}>{welcomeRegisterPage.formControlNewsletter}</Typography>} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Divider sx={{ my: 2 }} />
                            <Typography my={3} variant='subtitle2' color={'text.secondary'}>{welcomeRegisterPage.agbInfos}</Typography>
                        </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item >
                                <Button variant="contained" color='primary' type="submit" sx={{ width: '100px', float: 'left'}}>Speichern</Button>
                            </Grid>
                        </Grid>
            </FormControl>
        </form>
                </Box>
            </ProfileSettingsPage>
        </Main>
    );
}
