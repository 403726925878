import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {Box, Card, CardActionArea, CardContent, CardMedia, Typography, useTheme} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { setEditComponent, setPaymentComponent } from '../../store/Vanlifer/vanliferComponentsSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import HereMapTourComponent from './HereMapTourComponent';
import { fetchTourDetails, setTour, TourDistance } from '../../store/Vanlifer/tourSlice';
import { TourOverview } from '../../services/restserver-openapi';

type TourCardProp = {
  tour: TourOverview
  waypoints: string[];
}

function TourCard(props: TourCardProp) {
  const theme = useTheme();
  const HERE_MAP_ID = process.env.REACT_APP_HERE_MAP_ID!;
  const dispatch = useAppDispatch();
  const tourDistances = useAppSelector(state => state.tour.tourDistances);
  const [distance, setDistance] = useState(0);
  const userEntitlements = useAppSelector(state => state.user.entitlements);
  const canBookTour = useMemo(() => {
    return userEntitlements.some(entitlement => entitlement.featureId === 'vanliferbooktour' && entitlement.value === 'true');
  }, [userEntitlements]);

  function onClick(event: React.MouseEvent<HTMLButtonElement>) {
    dispatch(setTour(props.tour));
    if (canBookTour) {
      dispatch(setEditComponent(true));
      if (props.tour.tourId != null) {
        dispatch(fetchTourDetails(props.tour.tourId));
      }
    } else {
      dispatch(setPaymentComponent(true));
    }
  }

  const findObjectById = useCallback((id: number): TourDistance => {
    return tourDistances!.find(obj => obj.tourId === id) || { tourId: 0, distance: 0 };
  }, [tourDistances]);

  useEffect(() => {
    if (props.tour.tourId && props.tour.tourId > 0) {
      const tourDistance = findObjectById(props.tour.tourId);
      setDistance(tourDistance!.distance);
    }
  }, [findObjectById, props.tour.tourId, tourDistances]);

  function shortenToTwoDecimals(num: number) {
    return parseFloat(num.toFixed(2));
  }

  return (
    <Card
      sx={{
        width: 1,
        height: 1,
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 4,
        bgcolor: 'transparent',
        backgroundImage: 'none',
        '&:hover': {
          transform: `translateY(-${theme.spacing(0.5)})`,
        },
      }}>
      <CardActionArea onClick={onClick}>
        <CardMedia
          sx={{
            position: 'relative',
            height: 200,
            overflow: 'hidden',
            borderRadius: 2,
          }}>
          <HereMapTourComponent
            apiKey={HERE_MAP_ID}
            waypoints={props.waypoints}
            height={'200px'}
            editorSite={false}
            tourId={props.tour.tourId!} />
        </CardMedia>
        <CardContent>

          {/*//////////////////////////////////*/}
          {/*// Badges to be defined*/}
          {/*//////////////////////////////////*/}

          {props.tour.tourclass!.toString() == 'Class0' ?
            <>
              <Box
                padding={1}
                display={'inline-flex'}
                borderRadius={1}
                bgcolor={'green'}
                marginBottom={1}
              >
                <Typography sx={{ color: 'common.white', lineHeight: 1 }}>
                  Free
                </Typography>
              </Box>
              {props.tour.rating! > 4.5 ?
                <Box
                  padding={1}
                  display={'inline-flex'}
                  borderRadius={1}
                  bgcolor={'orange'}
                  marginBottom={1}
                  marginLeft={1}
                >
                  <Typography sx={{ color: 'common.white', lineHeight: 1 }}>
                    TopRated
                  </Typography>
                </Box>
                :
                <></>
              }
            </>
            :
            <></>
          }

          {props.tour.tourclass!.toString() == 'Class1' ?
            <>
              <Box
                padding={1}
                display={'inline-flex'}
                borderRadius={1}
                bgcolor={'blue'}
                marginBottom={1}
              >
                <Typography sx={{ color: 'common.white', lineHeight: 1 }}>
                  Selected
                </Typography>
              </Box>
              {props.tour.rating! > 4.5 ?
                <Box
                  padding={1}
                  display={'inline-flex'}
                  borderRadius={1}
                  bgcolor={'orange'}
                  marginBottom={1}
                  marginLeft={1}
                >
                  <Typography sx={{ color: 'common.blue', lineHeight: 1 }}>
                    TopRated
                  </Typography>
                </Box>
                :
                <></>
              }
            </>
            :
            <></>
          }

          {/*//////////////////////////////////*/}

          <Box marginTop={2}>
            <Typography fontWeight={700}>{props.tour.name}</Typography>
            <Typography variant={'caption'} color={'text.secondary'}>
              Tour ID: {props.tour.tourId}
            </Typography>
          </Box>
          <Box marginTop={2} display={'row'} alignItems={'center'}>
            <Typography variant={'body1'} color={'text.secondary'}>
              Anzahl Unterkünfte: {props.tour.stays?.length}
            </Typography>
            <Typography variant={'body1'} color={'text.secondary'}>
              Gesamtkilometer: {distance} km
            </Typography>
          </Box>
          <Box marginTop={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
            <Box display={'flex'} alignItems={'center'}>
              <StarIcon color="secondary" />
              <Typography fontWeight={700} color={'text.secondary'} sx={{ padding: 0.5 }}>
                {props.tour.rating ? shortenToTwoDecimals(props.tour.rating) : 0}
              </Typography>
            </Box>
            <Box>
              <Typography fontWeight={700} color={'text.secondary'} sx={{ padding: 0.5 }}>
                {props.tour.price} CHF
              </Typography>
            </Box>
          </Box>


          {/*<Grid container my={1} rowSpacing={0.1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>*/}
          {/*  <Grid item xs={12} sm={12} md={12}>*/}
          {/*    <Typography gutterBottom variant="h5" component="div">*/}
          {/*      Tour im {props.tour.name ? props.tour.name : ''}*/}
          {/*    </Typography>*/}
          {/*  </Grid>*/}
          {/*  <Grid item xs={12} sm={12} md={12}>*/}
          {/*    <Typography color="text.secondary">*/}
          {/*      Tour ID: {props.tour.tourId}*/}
          {/*    </Typography>*/}
          {/*    <Typography color="text.secondary">*/}
          {/*      Anzahl Campingplätze: {props.tour.stays?.length}*/}
          {/*    </Typography>*/}
          {/*    <Typography color="text.secondary">*/}
          {/*      Gesamtkilometer: {distance} km*/}
          {/*    </Typography>*/}
          {/*  </Grid>*/}
          {/*  <Grid item xs={12} sm={12} md={12}*/}
          {/*        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>*/}
          {/*    <Box sx={{ display: 'flex' }}>*/}
          {/*      <StarIcon color="secondary" />*/}
          {/*      <Typography>{props.tour.rating ? shortenToTwoDecimals(props.tour.rating) : 0}</Typography>*/}
          {/*    </Box>*/}
          {/*    <Box sx={{ border: `0px solid ${theme.palette.primary.main}`, borderRadius: 2 }}>*/}
          {/*      <Typography sx={{ padding: 0.5 }}>{props.tour.price} CHF</Typography>*/}
          {/*    </Box>*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default TourCard;
