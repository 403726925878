import {Grid, Typography} from '@mui/material';
import React from 'react';
import StarIcon from "@mui/icons-material/Star";
import {getReadableSiteType} from "../../utils/typeUtils";

interface AlternativeSiteProp {
    alternativeSite: any;
}

const AlternativeSingleSiteComponent: React.FC<AlternativeSiteProp> = ({alternativeSite}) => {
    // let rating = alternativeSite.rating
    //     ? <Typography display={"flex"} paddingRight={2}>{alternativeSite.rating} <StarIcon /></Typography>
    //     : null;

    return (<Grid container display="flex" flexDirection="row" sx={{marginBottom: '20px'}}>
        <Grid item xs={12} md={6}>
            <Grid container display="flex" flexDirection="column">
                <Grid item><Typography variant="body1">{getReadableSiteType(alternativeSite)}
                    Nr. {alternativeSite.compoundName}</Typography>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} md={6} display="flex" alignItems="center" justifyContent="end">
            <Grid container display="flex" alignItems="center" justifyContent="end">
                <Typography>{alternativeSite.priceDifference} Fr. </Typography>
            </Grid>
        </Grid>

    </Grid>);
}

export default AlternativeSingleSiteComponent;
