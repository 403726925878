import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Box,
    CircularProgress,
    Grid,
    useMediaQuery,
    useTheme,
} from '@mui/material';

interface Statistic {
    label: string;
    value: string;
}

interface StatisticsCardProps {
    markdownPath: string;
}

const parseMarkdownStats = (markdown: string): Statistic[] => {
    const lines = markdown.split('\n');
    const stats: Statistic[] = [];

    lines.forEach((line) => {
        const match = line.match(/^\s*[-*]\s+(.+?)\s*[:：]\s*([\d.,\w\s%€$+-]*)\s*$/);
        if (match) {
            stats.push({
                label: match[1].trim(),
                value: match[2].trim(),
            });
        } else {
            console.warn('Nicht erkannt:', line);
        }
    });

    return stats;
};

const VLTStatsCard: React.FC<StatisticsCardProps> = ({ markdownPath }) => {
    const [stats, setStats] = useState<Statistic[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        fetch(markdownPath)
            .then((res) => res.text())
            .then((text) => {
                const parsed = parseMarkdownStats(text);
                setStats(parsed);
                setLoading(false);
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    }, [markdownPath]);

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" py={3}>
                <CircularProgress />
            </Box>
        );
    }

    const isLargeNumber = (value: string): boolean => {
        const number = parseInt(value.replace(/[^\d]/g, ''));
        return number > 999;
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" py={3}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Grid container spacing={3}>
            {stats.map((stat, index) => (
                <Grid
                    item
                    xs={12}
                    md={isLargeNumber(stat.value) ? 12 : 4}
                    key={index}
                >
                    <Card sx={{ borderRadius: 3, boxShadow: 4, textAlign: 'center', py: 4}}>
                        <CardContent>
                            <Typography
                                variant="h3"
                                fontWeight={700}
                                sx={{
                                    background: 'linear-gradient(90deg, #ccda10, #1B2934)',
                                    WebkitBackgroundClip: 'text',
                                    WebkitTextFillColor: 'transparent',
                                }}
                            >
                                {stat.value}
                            </Typography>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ mt: 1 }}
                            >
                                {stat.label}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
};

export default VLTStatsCard;
