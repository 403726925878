import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import { TourDetail } from '../../services/restserver-openapi';
import {urlLogo, urlLogoDark} from "../../constants/constants";

const HERE_API_KEY = process.env.REACT_APP_HERE_MAP_ID!;

const styles = StyleSheet.create({
    page: { padding: 30, fontSize: 10 },
    section: { fontSize: 12, fontWeight: 300, marginBottom: 10 },
    header: { fontSize: 14, fontWeight: 700, marginBottom: 40 },
    smallText: {
        fontSize: 10,
        color: '#555'
    },
    addressText: {
        fontSize: 10,
        fontStyle: 'italic'
    },
    item: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5 },
    mapImage: { width: 500, height: 300, marginVertical: 10 },
    tableRowHeader: {
        flexDirection: 'row',
        borderBottom: '1px solid #000',
        paddingBottom: 4,
        marginBottom: 4
    },
    tableRow: {
        flexDirection: 'row',
        borderBottom: '0.5px solid #ccc',
        paddingVertical: 3
    },
    cellLarge: {
        flex: 3,
        paddingRight: 8
    },
    cell: {
        flex: 2,
        paddingRight: 8
    },
    cellSmall: {
        flex: 1,
        textAlign: 'right'
    },
    logo: {
        width: 100,
        height: 100,
        position: 'absolute',
        top: 20,
        right: 30
    },
    footer: {
        marginTop: 40,
        textAlign: 'center',
        fontSize: 14,
        fontStyle: 'italic'
    }
});

type Props = {
    tour: TourDetail;
};

const formatDate = (ms?: number) =>
    ms ? new Date(ms).toLocaleDateString('de-DE') : '–';

const TourPDFDocument: React.FC<Props> = ({ tour }) => {
    // ✅ Waypoints aus stays extrahieren
    const waypoints: string[] =
        tour.stays
            ?.map((stay) => {
                const x = stay.stayCompound?.coordinates?.x;
                const y = stay.stayCompound?.coordinates?.y;
                return x != null && y != null ? `${y},${x}` : null;
            })
            .filter((wp): wp is string => wp !== null) ?? [];

    const hasValidWaypoints = waypoints.length >= 2;

    const routeString = waypoints.join(',');
    const mapImageUrl = hasValidWaypoints
        ? `https://image.maps.ls.hereapi.com/mia/1.6/route?apiKey=${HERE_API_KEY}&r0=${routeString}&w=600&h=400&lc=1652B4&sc=888888&lw=6&t=normal.day`
        : '';

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <Image src={urlLogoDark} style={styles.logo} />
                <View>
                    <Text style={styles.header}>Deine Tour: {tour.name}</Text>
                </View>
                {/* Solange die HERE Image Rendering API nicht freigeschaltet ist */}
                {/*{hasValidWaypoints && (*/}
                {/*    <View style={styles.section}>*/}
                {/*        <Text style={styles.header}>Kartenansicht</Text>*/}
                {/*        <Image src={mapImageUrl} style={styles.mapImage} />*/}
                {/*        <Text>Map-URL: {mapImageUrl}</Text>*/}
                {/*    </View>*/}
                {/*)}*/}
                {tour.stays?.length! > 0 && (
                    <View style={styles.section}>
                        <Text style={styles.header}>Deine Unterkünfte</Text>

                        {/* Tabellen-Header */}
                        <View style={styles.tableRowHeader}>
                            <Text style={styles.cellLarge}>Zeitraum</Text>
                            <Text style={styles.cell}>Unterkunft</Text>
                            <Text style={styles.cellSmall}>Preis (€/CHF)</Text>
                        </View>

                        {/* Unterkunftszeilen */}
                        {tour.stays?.map((stay, idx) => (
                            <View style={styles.tableRow} key={idx}>
                                <Text style={styles.cell}>
                                    {formatDate(stay.fromDate)} – {formatDate(stay.toDate)}
                                </Text>

                                <View style={styles.cellLarge}>
                                    <Text>{stay.stayCompound?.name ?? '–'}</Text>
                                    <Text style={styles.addressText}>
                                        {stay.stayCompound?.addressLine1}, {stay.stayCompound?.zipCode} {stay.stayCompound?.city}
                                    </Text>
                                    <Text style={styles.smallText}>
                                        {stay.stayCompound?.guestInformation}
                                    </Text>
                                </View>

                                <Text style={styles.cellSmall}>
                                    {stay.price != null ? stay.price.toFixed(2) : '–'}
                                </Text>
                            </View>
                        ))}
                    </View>
                )}
                <View style={styles.footer}>
                    <Text>Danke, dass du Teil der Open Travel Community bist!</Text>
                    <Text>Wir wünschen Dir ein tolles Reiseerlebnis!</Text>
                </View>
            </Page>
        </Document>
    );
};

export default TourPDFDocument;