import React from 'react';
import { Box, Tooltip } from '@mui/material';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

const PremiumFeatureBadge: React.FC = () => (
    <Tooltip title="Premium Feature">
        <Box
            component="span"
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 20,
                height: 20,
                backgroundColor: '#ccda10',
                borderRadius: '50%',
                ml: 1,
            }}
        >
            <MonetizationOnIcon fontSize="inherit" sx={{ color: 'lightgoldenrodyellow', fontSize: 18 }} />
        </Box>
    </Tooltip>
);

export default PremiumFeatureBadge;