import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import "@here/maps-api-for-javascript";
import {useAppDispatch, useAppSelector} from "../../hooks/hooks";
import {
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel, FormLabel, FormControl,
    Grid,
    MenuItem,
    TextField,
    Typography,
    InputLabel,
    Paper
} from "@mui/material";
import moment, {Moment} from "moment";
import {Compound} from "../../services/restserver-openapi";
import {createCompound, defaultCompound, editCompound} from "../../store/Host/compoundsSlice";
import {countryCodes as countries} from "../../constants/country_code";
import UploadPictures from "../presentationalComponents/UploadPictures";
import TimePickerVanlife from "../presentationalComponents/TimePickerVanlife";
import {fetchAllRegions} from "../../store/Vanlifer/regionSlice";
import {
    fetchExistingImages,
    handleImageDelete,
    handleImageUploadCompound,
    handleUpdateImageChange
} from "../../utils/Host/offerImageUtil";
import {Save} from "@mui/icons-material";
import {addDraggableMarker} from "../../utils/hereMapUtils";
import {getReadableCompoundType} from "../../utils/typeUtils";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";

type CreateCompoundFormProps = {
    onBack: (compoundId?: number) => void
}

const CompoundFormComponent: React.FC<CreateCompoundFormProps> = ({onBack}) => {
    const dispatch = useAppDispatch();
    const HERE_MAP_ID = process.env.REACT_APP_HERE_MAP_ID!;
    const compound = useAppSelector(state => state.compounds.selectedCompound);
    const [compoundData, setCompoundData] = useState<Compound>(defaultCompound);

    const mapRef = useRef<HTMLDivElement>(null);
    const mapInstance = useRef<H.Map | null>(null);
    const uiInstance = useRef<H.ui.UI | null>(null);
    const behaviorInstance = useRef<H.mapevents.Behavior | null>(null);

    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [files, setFiles] = useState<string[]>([]);
    const [loadingSave, setLoadingSave] = useState(false);


    const [arrivalTime, setArrivalTime] = React.useState<Moment | null>(
        moment(compound.arrivalTime, 'THH:mm')
    );
    const [departureTime, setDepartureTime] = React.useState<Moment | null>(
        moment(compound.departureTime, 'THH:mm')
    );

    const [countrySelected, setCountrySelected] = useState("Schweiz");
    const [selectedType, setSelectedType] = useState(compoundData.compoundType);


    const platform = useRef(new H.service.Platform({
        apikey: HERE_MAP_ID
    }));

    useEffect(() => {
        if (mapRef.current && !mapInstance.current) {
            const defaultLayers : any = platform.current.createDefaultLayers();
            const map = new H.Map(
                mapRef.current,
                defaultLayers.vector.normal.map,
                {
                    center: {lat: 46.4757, lng: 8.1355},
                    zoom: 6,
                    pixelRatio: window.devicePixelRatio || 1
                }
            );
            uiInstance.current = H.ui.UI.createDefault(map, defaultLayers, 'de-DE');
            mapInstance.current = map;
            behaviorInstance.current = new H.mapevents.Behavior(new H.mapevents.MapEvents(mapInstance.current));

            if (compound.coordinates?.x && compound.coordinates?.y) {
                addDraggableMarker(mapInstance.current, behaviorInstance.current, compound.coordinates.x, compound.coordinates.y, (lat, lng) => {
                    setCompoundData(prev => ({
                        ...prev,
                        coordinates: { x: lat, y: lng }
                    }));
                });
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapRef.current]);

    useEffect(() => {
        dispatch(fetchAllRegions)
    }, [dispatch]);

    useEffect(() => {
        setCompoundData(compound)
        setCountrySelected(countries.find((country) => country.countryCode === compound.country)?.countryName ?? 'Schweiz')
        setSelectedType(compound.compoundType);
    }, [compound]);

    const getCoordinatesFromAddress = () => {

                    const service = platform.current.getSearchService()

                    service.geocode({
                        q: `${compoundData.addressLine1}, ${compoundData.zipCode} ${compoundData.city}`
                    }, (result: any) => {
                        if (result.items.length > 0) {
                            const position = result.items[0].position;
                            setCompoundData({...compoundData, coordinates: {x: position.lat, y: position.lng}})
                            if (!mapInstance.current || !behaviorInstance.current) { return;}
                            addDraggableMarker(mapInstance.current, behaviorInstance.current, position.lat, position.lng, (lat, lng) => {
                                setCompoundData({...compoundData, coordinates: {x: lat, y: lng}})
                            });
                        }
                    }, (error) => {
                        console.error("Error fetching coordinates: ", error);
                    });
    }

    const handleArrivalTimeChange = (newValue: Moment | null) => {
        setArrivalTime(newValue);
        setCompoundData({...compoundData, arrivalTime: newValue?.format('HH:mm')})
    };

    const handleDepartureTimeChange = (newValue: Moment | null) => {
        setDepartureTime(newValue);
        setCompoundData({...compoundData, departureTime: newValue?.format('HH:mm')})
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (compoundData.compoundId) {
            setLoadingSave(true);
            await handleImageUploadCompound(compoundData, selectedFiles);
            await dispatch(editCompound(compoundData)).then((response) => {
                if (editCompound.fulfilled.match(response)) {
                    setLoadingSave(false);
                    onBack(response.payload.compoundId);
                }
            });
        } else {
            await dispatch(createCompound(compoundData)).then(async (response) => {
                if (createCompound.fulfilled.match(response)) {
                    setCompoundData(response.payload);
                    await handleImageUploadCompound(response.payload, selectedFiles);
                    onBack(response.payload.compoundId);
                }
            });
        }
    };

    function getCountryEnumFromString(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        let inputCountryEnum = Compound.country.MN;
        for (let i = 0; i < countries.length; i++) {
            if (countries[i].countryName === event.target.value) {
                inputCountryEnum = countries[i].countryCode as Compound.country;
            }
        }
        return inputCountryEnum;
    }

    function handleCompoundCountryInput(event: ChangeEvent<HTMLTextAreaElement>) {
        setCountrySelected(event.target.value);
        let inputCountryEnum = getCountryEnumFromString(event);
        setCompoundData({...compoundData, country: inputCountryEnum});
    }

    const handleTypeChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newType = event.target.value as Compound.compoundType;
        setSelectedType(newType);
        setCompoundData({ ...compoundData, compoundType: newType });
    };


    useEffect(() => {
        if (compoundData.imageIds) {
            fetchExistingImages(compoundData.imageIds, setSelectedFiles, selectedFiles);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compoundData.imageIds]);


    const handleUploadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleUpdateImageChange(e, files, setFiles, selectedFiles, setSelectedFiles);
    }

    const handleUploadDelete = (index: number) => {
        handleImageDelete(index, files, setFiles, selectedFiles, setSelectedFiles);
    }

    const facilities = [
        { key: 'wifiAvailable', label: 'Wifi / WLAN', checked: compoundData?.wifiAvailable || false },
        { key: 'sauna', label: 'Sauna', checked: compoundData?.sauna || false },
        { key: 'massage', label: 'Massage od. Wellness', checked: compoundData?.massage || false },
        { key: 'hotTubOrSpa', label: 'Whirlpool / SPA Pool', checked: compoundData?.hotTubOrSpa || false },
        { key: 'swimmingPool', label: 'Schwimmbad', checked: compoundData?.swimmingPool || false },
        { key: 'cityNearby', label: 'Stadt in der Nähe', checked: compoundData?.cityNearby || false },
        { key: 'mountains', label: 'Berge in der Nähe', checked: compoundData?.mountains || false },
        { key: 'waterbody', label: 'Gewässer in der Nähe', checked: compoundData?.waterbody || false },
        { key: 'culturalSiteOrAttraction', label: 'Kultur / Sehenswürdigkeit in der Nähe', checked: compoundData?.culturalSiteOrAttraction || false },
        { key: 'cateringOrRestaurant', label: 'Verpflegung / Restaurant', checked: compoundData?.cateringOrRestaurant || false },
        { key: 'onlyBreakfastPossible', label: 'Nur Frühstück möglich', checked: compoundData?.onlyBreakfastPossible || false },
        { key: 'breadRollService', label: 'Brötchenservice', checked: compoundData?.breadRollService || false },
        { key: 'wholeDayReception', label: '24h Rezeption', checked: compoundData?.wholeDayReception || false },
        { key: 'ownParkingSpace', label: 'Eigener Parkplatz', checked: compoundData?.ownParkingSpace || false },
        { key: 'parkingNearby', label: 'Parkplatz in der Nähe', checked: compoundData?.parkingNearby || false },
        { key: 'publicTransportation', label: 'ÖV in der Nähe', checked: compoundData?.publicTransportation || false },
        { key: 'sustainable', label: 'Nachhaltiger Betrieb', checked: compoundData?.sustainable || false },
        { key: 'childFriendly', label: 'Kinderfreundlich', checked: compoundData?.childFriendly || false },
        { key: 'shopOrSupermarket', label: 'Shop / Supermarkt in der Nähe', checked: compoundData?.shopOrSupermarket || false },
        { key: 'playGround', label: 'Spielplatz', checked: compoundData?.playGround || false },
        { key: 'wasteWaterDisposal', label: 'Abwasser/Entsorgung', checked: compoundData?.wasteWaterDisposal || false },
        { key: 'freshWater', label: 'Frischwasser', checked: compoundData?.freshWater || false },
        { key: 'sportOrAnimation', label: 'Sport / Animation', checked: compoundData?.sportOrAnimation || false },
        { key: 'grillOrBarbecueArea', label: 'Grill/ Grillstelle', checked: compoundData?.grillOrBarbecueArea || false },
    ];

    const handleFacilityChange = (key: string, checked: boolean) => {
        setCompoundData({
            ...compoundData,
            [key]: checked,
        });
    };
    return (
          <form onSubmit={handleSubmit}>
             <Grid container spacing={1}>
                <Grid item xs={12} >
                    <Typography variant="h5" component="h3" gutterBottom={true}>
                        Basisdaten zu Deinem Betrieb
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel shrink htmlFor="rating-classification">
                            Klassifizierung Deines Betriebs
                        </InputLabel>
                        <Box
                            id="rating-classification"
                            sx={{
                                border: '0px solid rgba(0, 0, 0, 0.23)',
                                borderRadius: '4px',
                                padding: '18.5px 14px 16.5px', // angepasst an TextField padding
                                minHeight: '56px',
                                display: 'flex',
                                alignItems: 'center',
                                position: 'relative',
                            }}
                        >
                            <Rating
                                name="classification"
                                value={compoundData.classification}
                                onChange={(event, newValue) => {
                                    setCompoundData({ ...compoundData, classification: newValue! });
                                }}
                                max={5}
                            />
                        </Box>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        select
                        label="Typ"
                        fullWidth
                        value={selectedType}
                        onChange={handleTypeChange}
                        required={true}
                    >
                        {Object.keys(Compound.compoundType).map((type) => (
                            <MenuItem key={type} value={type}>
                                {getReadableCompoundType({ compoundType: Compound.compoundType[type as keyof typeof Compound.compoundType] })}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="Name"
                        fullWidth
                        value={compoundData.name}
                        onChange={(e) => setCompoundData({...compoundData, name: e.target.value})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Website"
                        fullWidth
                        value={compoundData.externalLink}
                        onChange={(e) => setCompoundData({...compoundData, externalLink: e.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Telefon"
                        fullWidth
                        value={compoundData.phone}
                        onChange={(e) => setCompoundData({...compoundData, phone: e.target.value})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Adresse"
                        fullWidth
                        value={compoundData.addressLine1}
                        onChange={(e) => setCompoundData({...compoundData, addressLine1: e.target.value})}
                    />
                </Grid>
                <Grid item xs={4} md={2}>
                    <TextField
                        type={"number"}
                        label="PLZ"
                        fullWidth
                        value={compoundData.zipCode}
                        onChange={(e) => setCompoundData({...compoundData, zipCode: e.target.value})}
                    />
                </Grid>
                <Grid item xs={8} md={6}>
                    <TextField
                        label="Stadt"
                        fullWidth
                        value={compoundData.city}
                        onChange={(e) => setCompoundData({...compoundData, city: e.target.value})}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField select id="select-country"
                               fullWidth
                               label="Land" value={countrySelected}
                               onChange={handleCompoundCountryInput} required>
                        {countries.map((country) => (
                            <MenuItem key={country.countryCode} value={country.countryName}>
                                {country.countryName}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography variant="h5" component="h3" gutterBottom={true}>
                        Standort und Geodaten
                    </Typography>
                    <Typography gutterBottom={true}>Gib einfach oben die Adresse Deines Betriebs ein und klick auf den Button – schon werden automatisch die passenden Koordinaten (Breiten- und Längengrad) für dich ermittelt. Die Position siehst du direkt auf der Karte und kannst sie dort bei Bedarf ganz easy per Klick oder Verschieben anpassen.</Typography>
                </Grid>
                 <Grid item xs={6}>
                     <Button variant="contained" color="primary" onClick={getCoordinatesFromAddress}>
                         Koordinaten aus Adresse generieren
                     </Button>
                 </Grid>
                <Grid item xs={12}>
                    <div ref={mapRef} style={{height: '400px'}} />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        type={"number"}
                        label="Latitude"
                        fullWidth
                        value={compoundData.coordinates?.x}
                        onChange={(e) => setCompoundData({...compoundData, coordinates: {x: Number(e.target.value), y: compoundData.coordinates?.y}})}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        type={"number"}
                        label="Longitude"
                        fullWidth
                        value={compoundData.coordinates?.y}
                        onChange={(e) => setCompoundData({...compoundData, coordinates: {x: compoundData.coordinates?.x, y: Number(e.target.value)}})}
                    />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography variant="h5" component="h3">
                        Gästeinformationen
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Schreibe einen persönlichen Gruss oder einen sympathischen Text zu Deinem Betrieb"
                        fullWidth
                        multiline
                        rows={4}
                        value={compoundData.guestInformation}
                        onChange={(e) => setCompoundData({...compoundData, guestInformation: e.target.value})}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TimePickerVanlife
                        fullWidth
                        handleChange={handleArrivalTimeChange}
                        value={arrivalTime}
                        label="Standard Check-In Zeit"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TimePickerVanlife
                        fullWidth
                        handleChange={handleDepartureTimeChange}
                        value={departureTime}
                        label="Standard Check-Out Zeit"
                    />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography variant="h5" component="h4" gutterBottom>
                        Daten zur Ausstattung
                    </Typography>
                    <Typography gutterBottom={true}>Setz einfach die Häkchen bei den Ausstattungsmerkmalen, die auf deinen Betrieb zutreffen. So können später alle auf einen Blick sehen, wie Dein Betrieb ausgestattet ist und was für Services du anbietest – ganz egal ob WLAN, Brötchenservice oder Parkplätze vor Ort.</Typography>
                    <Grid container spacing={2}>
                        {facilities.map((facility) => (
                            <Grid item xs={6} sm={6} md={4} lg={3} key={facility.key}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={facility.checked}
                                            onChange={(e) => handleFacilityChange(facility.key, e.target.checked)}
                                        />
                                    }
                                    label={facility.label}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                {/* Bilder */}
                <Grid item xs={12} sx={{ mt: 2 }}>
                    <Typography variant="h5" component="h3">
                        Bilder
                    </Typography>
                    <UploadPictures handleChange={handleUploadChange} selectedFiles={selectedFiles} handleDelete={handleUploadDelete} />
                </Grid>

                {/* Submit CallToActionButton */}
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" type="submit" endIcon={loadingSave ? <CircularProgress /> : <Save  />}>
                        Speichern
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default CompoundFormComponent;
