import React, { useEffect, useState } from 'react';
import {
    Card, CardContent, TextField, Checkbox, FormControlLabel,
    Button, Box, Table, TableBody, TableCell, TableContainer,
    TableHead, TableRow, Paper, CircularProgress, Typography, MenuItem, Select, InputLabel, FormControl
} from '@mui/material';
import { CheckCircle as CheckCircleIcon, Cancel as CancelIcon } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import { fetchCompounds } from '../../store/Host/compoundsSlice';
import { Compound } from '../../services/restserver-openapi';
import InfoIcon from "@mui/icons-material/Info";
import {Link as RouterLink} from "react-router-dom";

const COMPOUND_TYPES = ['CAMPING', 'PARKING', 'SERVICE']; // Beispiel – ggf. dynamisch laden

const CompoundTable = () => {
    const dispatch = useAppDispatch();
    const compounds: Compound[] = useAppSelector((state) => state.compounds.compounds);
    const loading = useAppSelector((state) => state.compounds.loading);

    const [hostId, setHostId] = useState<number>(21);
    const [revised, setRevised] = useState<boolean | undefined>(undefined);
    const [visible, setVisible] = useState<boolean | undefined>(undefined);
    const [types, setTypes] = useState<string[]>([]);

    const handleLoad = () => {
        dispatch(fetchCompounds({
            hostId,
            regionId: 0,
            revised,
            visible,
            types
        }));
    };

    useEffect(() => {
        handleLoad();
    }, []); // initial load

    return (
        <>
            {/* Card 1: Filter */}
            <Card sx={{ mb: 3 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Filter:
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', flexWrap: 'wrap' }}>
                        <FormControl sx={{ minWidth: 200 }}>
                            <InputLabel>Compound-Typ</InputLabel>
                            <Select
                                multiple
                                value={types}
                                onChange={(e) => setTypes(e.target.value as string[])}
                                label="Compound-Typ"
                            >
                                {COMPOUND_TYPES.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Host ID"
                            type="number"
                            value={hostId}
                            onChange={(e) => setHostId(Number(e.target.value))}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={revised === true}
                                    indeterminate={revised === undefined}
                                    onClick={() =>
                                        setRevised(
                                            revised === true ? false : revised === false ? undefined : true
                                        )
                                    }
                                />
                            }
                            label={`Revised: ${
                                revised === true ? "Ja" : revised === false ? "Nein" : "Egal"
                            }`}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={visible === true}
                                    indeterminate={visible === undefined}
                                    onClick={() =>
                                        setVisible(
                                            visible === true ? false : visible === false ? undefined : true
                                        )
                                    }
                                />
                            }
                            label={`Visible: ${
                                visible === true ? "Ja" : visible === false ? "Nein" : "Egal"
                            }`}
                        />
                        <Button variant="contained" onClick={handleLoad}>
                            Aktualisieren
                        </Button>
                    </Box>
                </CardContent>
            </Card>

            {/* Card 2: Tabelle */}
            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Resultate:
                    </Typography>
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead sx={{ bgcolor: 'alternate.main' }}>
                                    <TableRow>
                                        <TableCell><Typography
                                            color={'text.secondary'}
                                            variant={'caption'}
                                            fontWeight={700}
                                            sx={{ textTransform: 'uppercase' }}
                                        >Id</Typography></TableCell>
                                        <TableCell><Typography
                                            color={'text.secondary'}
                                            variant={'caption'}
                                            fontWeight={700}
                                            sx={{ textTransform: 'uppercase' }}
                                        >Name</Typography></TableCell>
                                        <TableCell><Typography
                                            color={'text.secondary'}
                                            variant={'caption'}
                                            fontWeight={700}
                                            sx={{ textTransform: 'uppercase' }}
                                        >HostId</Typography></TableCell>
                                        <TableCell><Typography
                                            color={'text.secondary'}
                                            variant={'caption'}
                                            fontWeight={700}
                                            sx={{ textTransform: 'uppercase' }}
                                        >Typ</Typography></TableCell>
                                        <TableCell><Typography
                                            color={'text.secondary'}
                                            variant={'caption'}
                                            fontWeight={700}
                                            sx={{ textTransform: 'uppercase' }}
                                        >Revised</Typography></TableCell>
                                        <TableCell><Typography
                                            color={'text.secondary'}
                                            variant={'caption'}
                                            fontWeight={700}
                                            sx={{ textTransform: 'uppercase' }}
                                        >Visible</Typography></TableCell>
                                        <TableCell><Typography
                                            color={'text.secondary'}
                                            variant={'caption'}
                                            fontWeight={700}
                                            sx={{ textTransform: 'uppercase' }}
                                        >Page</Typography></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {compounds.map((compound) => (
                                        <TableRow key={compound.compoundId}>
                                            <TableCell>{compound.compoundId}</TableCell>
                                            <TableCell>{compound.name}</TableCell>
                                            <TableCell>{compound.hostId}</TableCell>
                                            <TableCell>{compound.compoundType}</TableCell>
                                            <TableCell>
                                                {compound.revised
                                                    ? <CheckCircleIcon color="success" />
                                                    : <CancelIcon color="error" />}
                                            </TableCell>
                                            <TableCell>
                                                {compound.visibleCompound
                                                    ? <CheckCircleIcon color="success" />
                                                    : <CancelIcon color="error" />}
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="outlined"
                                                    startIcon={<InfoIcon />}
                                                    component={RouterLink}
                                                    to={``}
                                                >
                                                    Details
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}
                </CardContent>
            </Card>
        </>
    );
};

export default CompoundTable;
