import React from 'react';
import './theme/index.css';
import {Route, Routes} from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import CompoundManagement from "./pages/HostPages/CompoundManagement";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {GoogleOAuthProvider} from '@react-oauth/google';
import HostOverview from "./pages/HostPages/HostOverview";
import SitesManagement from "./pages/HostPages/SitesManagement";
import TravelPlanningOverview from "./pages/VanliferPages/VanliferOverview/TravelPlanningOverview";
import Footer from "./components/Footer";
import AdditionalInfoForm from './pages/VanliferPages/AdditionalInfoForm';
import Container from "@mui/material/Container";
import TourPage from "./pages/VanliferPages/TourPage";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import {CampsiteDetailPage} from "./pages/CampsiteDetailPage";
import Kudos from "./components/Kudos";
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";
import store from "./store/store";
import Page from './components/Page';
import Support from "./pages/ProfilePages/Support";
import PersonalDataEditor from "./pages/ProfilePages/PersonalDataEditor";
import NotificationPreferencesEditor from "./pages/ProfilePages/NotificationPreferencesEditor";
import Abos from "./pages/ProfilePages/Abos";
import RoleSpecific from "./pages/ProfilePages/RoleSpecific";
import { User } from './services/restserver-openapi';
import PrivateRoute from "./components/PrivateRoute";
import LoginPage from './pages/LoginPage';
import RegisterHost from "./pages/Registration/RegisterHost";
import RegisterVanlifer from "./pages/Registration/RegisterVanlifer";
import RegionsPage from "./pages/VanliferPages/RegionsPage";
import RegionSinglePage from "./pages/VanliferPages/RegionSinglePage";
import RegisterAgent from './pages/Registration/RegisterAgent';
import AgentUserOverviewPage from "./pages/AgentPages/AgentUserOverviewPage";
import AgentUserDetailPage from "./pages/AgentPages/AgentUserDetailPage";
import AgentTourOverviewPage from "./pages/AgentPages/AgentTourOverviewPage";
import BookingOverviewPage from "./pages/VanliferPages/BookingOverviewPage";
import CompoundManagementComponent from './components/hostComponents/CompoundManagementComponent';
import GamificationProfile from './components/vanliferComponents/Gamification/GamificationProfile';
import NewsFeedCard from './components/vanliferComponents/EarlyAccess/NewsFeedCard'
import EarlyAccessPage from "./pages/VanliferPages/EarlyAccessPage";
import AgentCompoundOverviewPage from "./pages/AgentPages/AgentCompoundOverview";

function App() {
    const LOGIN_CLIENT_ID = process.env.REACT_APP_LOGIN_CLIENT_ID!;

    return (
        <React.StrictMode>
            <Provider store={store}>
                <Page>
                <Router>
                        <GoogleOAuthProvider clientId={LOGIN_CLIENT_ID}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Container id={'page-container'} maxWidth={false} disableGutters>
                                    <Kudos/>
                                    <ResponsiveAppBar/>
                                    <Container id={'content-wrap'} maxWidth={false} disableGutters>
                                        <Routes>
                                            <Route index element={<LandingPage/>}/>
                                            <Route path="login" element={<LoginPage />} />
                                            <Route path="register">
                                                <Route path={"host"} element={<RegisterHost/>}/>
                                                <Route path={"vanlifer"} element={<RegisterVanlifer/>}/>
                                                <Route path={"agent"} element={<RegisterAgent/>}/>
                                            </Route>
                                            <Route path="vanlifer" element={<PrivateRoute roles={[User.userRole.VANLIFER, User.userRole.AGENT]} />}>
                                                <Route path="TravelPlanningOverview" element={<TravelPlanningOverview/>}/>
                                                <Route path="BookingOverview" element={<BookingOverviewPage/>}/>
                                                <Route path="Community" element={<GamificationProfile/>}/>
                                                <Route path="EarlyAccess" element={<EarlyAccessPage/>}/>
                                                <Route path="Tour/:tourId" element={<TourPage/>}/>
                                                <Route path="Campsite/:campsiteId" element={<CampsiteDetailPage/>}/>
                                                <Route path="AdditionalInfoForm/:idsBase64" element={<AdditionalInfoForm/>}/>
                                                <Route path="regions" element={<RegionsPage/>}/>
                                                <Route path="regions/:regionId" element={<RegionSinglePage/>}/>
                                            </Route>
                                            <Route path="travelAgent" element={<PrivateRoute roles={[User.userRole.AGENT]}/>}>
                                                <Route path="AgentUserOverview" element={<AgentUserOverviewPage/>}/>
                                                <Route path="AgentCompoundOverview" element={<AgentCompoundOverviewPage/>}/>
                                                <Route path="AgentTourOverview" element={<AgentTourOverviewPage/>}/>
                                                <Route path="user/:userId/:userRole" element={<AgentUserDetailPage/>}/>
                                            </Route>
                                            <Route path="host" element={<PrivateRoute roles={[User.userRole.HOST]}/>}>
                                                <Route path="HostOverview" element={<HostOverview/>}/>
                                                <Route path="SitesManagement" element={<SitesManagement/>}/>
                                                <Route path="Compounds" element={<CompoundManagementComponent />}/>
                                            </Route>
                                            <Route path="profil" element={<PrivateRoute roles={[User.userRole.HOST,User.userRole.VANLIFER,User.userRole.AGENT]}/>}>
                                                <Route path="spezial" element={<RoleSpecific/>}/>
                                                <Route path="personal" element={<PersonalDataEditor/>}/>
                                                <Route path="notifications" element={<NotificationPreferencesEditor/>}/>
                                                <Route path="abos" element={<Abos/>}/>
                                                <Route path="support" element={<Support/>}/>
                                            </Route>
                                        </Routes>
                                    </Container>
                                    <Footer/>
                                </Container>
                            </LocalizationProvider>
                        </GoogleOAuthProvider>
                </Router>
                </Page>
            </Provider>
        </React.StrictMode>
    );
}

export default App;
