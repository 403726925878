import React, {useMemo} from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { Box, Card, Container, Grid, Typography } from '@mui/material';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Link } from 'react-router-dom';
import Main from '../../layouts/Main';
import NewsFeed from '../../components/vanliferComponents/EarlyAccess/NewsFeedCard';
import FeatureVoting from '../../components/vanliferComponents/EarlyAccess/FeatureVotingCard';
import SupporterWallOfFame from "../../components/vanliferComponents/EarlyAccess/SupporterWallOfFame";
import VLTStatsCard from "../../components/vanliferComponents/EarlyAccess/VLTStatsCard";

import { newsfile, statsfile, featurefile } from '../../constants/constants';
import {useAppSelector} from "../../hooks/hooks";
import EarlyAccessStateCard from "../../components/vanliferComponents/EarlyAccess/EarlyAccessStateCard";
import YouTubeCard from "../../components/presentationalComponents/YouTubeCard";
import CommunityChannels from "../../components/vanliferComponents/EarlyAccess/CommunityChannels";


const EarlyAccessPage = (): JSX.Element => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'));
    const userEntitlements = useAppSelector(state => state.user.entitlements);

    const hasEarlyAccess = useMemo(() => {
        return userEntitlements.some(entitlement => entitlement.featureId === 'vanliferhasearlyaccess' && entitlement.value === "true")
    }, [userEntitlements]);

    return (
        <Main>
            <Container sx={{ minHeight: '100vh', py: 5 }}>
                <Box textAlign="center" py={5}>
                    <Typography variant="h2" fontWeight={700} gutterBottom>
                        Sei von Anfang an dabei!
                    </Typography>
                    <Typography variant="h6" color="text.secondary" paragraph>
                        Wir entwickeln eine innovative Lösung – und du kannst Teil davon sein!
                        Mit unserem Early Access bekommst du exklusive Einblicke, kannst Feedback geben
                        und hast Einfluss auf die Entwicklung.
                    </Typography>
                </Box>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                        <Card sx={{ padding: 4, borderRadius: 3, boxShadow: 3, width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h5" fontWeight={600} gutterBottom>
                                Warum Early Access?
                            </Typography>
                            <List sx={{ flexGrow: 1 }}>
                                {[
                                    'Erhalte exklusive Updates und Features vor allen anderen.',
                                    'Gib direktes Feedback und gestalte die Lösung mit.',
                                    'Werde Teil unserer Community und bringe deine Ideen ein.',
                                ].map((text, index) => (
                                    <ListItem key={index} disableGutters>
                                        <ListItemIcon sx={{ minWidth: '32px' }}>
                                            <CheckIcon color='secondary' fontSize="medium" />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={text}
                                            primaryTypographyProps={{ variant: 'body1', color: 'text.secondary' }}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        </Card>
                    </Grid>

                    <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                        <EarlyAccessStateCard hasEarlyAccess={hasEarlyAccess} />
                    </Grid>
                </Grid>


                <SupporterWallOfFame />

                {hasEarlyAccess && (
                    <>
                        <Grid mt={2} container spacing={4}>
                            <Grid item xs={12} md={8}>
                                <Box>
                                    <Typography variant="h4" fontWeight={600} textAlign="center" gutterBottom>
                                        What's coming up next?
                                    </Typography>
                                    <Typography variant="h6" color="text.secondary" textAlign="center" gutterBottom>
                                        Exklusive News für Early Access User: An was arbeiten wir gerade und was wird bald fertig sein.
                                    </Typography>
                                    <NewsFeed markdownPath={newsfile}/>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box>
                                    <Typography variant="h4" fontWeight={600} textAlign="center" gutterBottom>
                                        Facts & Figures
                                    </Typography>
                                    <Typography variant="h6" color="text.secondary" textAlign="center" gutterBottom>
                                        Check die aktuellen Stats
                                    </Typography>
                                    <VLTStatsCard markdownPath={statsfile}/>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid mt={2} container spacing={4}>
                            <Grid item xs={12}>
                                <CommunityChannels/>
                            </Grid>
                        </Grid>
                        <Grid mt={2} container spacing={4}>
                            <Grid item xs={12}>
                                <Box>
                                    <Typography variant="h4" fontWeight={600} textAlign="center" gutterBottom>
                                        Stimme für neue Features ab!
                                    </Typography>
                                    <Typography variant="h6" color="text.secondary" textAlign="center" gutterBottom>
                                        Deine Stimme hilft uns zu priorisieren, was als Nächstes kommt.
                                    </Typography>
                                    <FeatureVoting/>
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Box textAlign="center" py={5}>
                    <Typography variant="h4" fontWeight={600} gutterBottom>
                        Sei dabei – wir freuen uns auf dich!
                    </Typography>
                </Box>
            </Container>
        </Main>
    );
};

export default EarlyAccessPage;