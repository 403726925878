/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import imgCalendar from '../../assets/Images/calendar.jpg';
import {Link} from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material";

const HostApplication = (): JSX.Element => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
    <Box position={'relative'}>
      <Grid container>
        <Grid item xs={12} sm={6} data-aos={'fade-up'}>
          <Box marginBottom={2}>
            <Typography
              variant="h4"
              color="text.primary"
              fontWeight={700}
            >
                Du bist Gastgeber und willst Teil der Open Travel Community werden?
            </Typography>
          </Box>
          <Box marginBottom={2}>
          <Typography variant="h6" color={'text.secondary'} data-aos={'fade-up'}>
            Werde sichtbar - Plege Dein Angebot - Empfange nette Gäste
          </Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography
              variant="body1"
              component="p"
              color="text.primary"
            >
                Egal ob Hotel, Pension oder Campingplatz, wir freuen uns auf Dein Mitmachen.
                Am besten registrierst Du Dich und erstellst Deine Gastgeberseite. Fall Du nicht weiterkommst oder Hilfe brauchst nimm einfach Kontakt auf und schreib uns eine Email.
                  Gerne möchten wir speziell auch dem Agrotourismus und Privatanbietern das Gastgebersein vereinfachen.
                  Deshalb haben einen Buchungskalender entwickelt, mit dem Du alle Deine Angebote verwalten kannst.
              </Typography>
          </Box>
          <Button
            component={Link}
            to={'/register/host'}
            variant="contained"
            color="primary"
            size="large"
            fullWidth={!isMd}
          >
            Jetzt Gastgeber werden...
          </Button>
        </Grid>
      </Grid>
      <Box
        component={'img'}
        src={imgCalendar}
        sx={{
          maxWidth: '500px',
          height: 'auto',
          position: 'absolute',
          bottom: '30px',
          right: 0,
          display: { xs: 'none', sm: 'block' },
        }}
      />
    </Box>
  );
};

export default HostApplication;
