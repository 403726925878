import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { fetchAllRegions, setRegionById, fetchCompoundsOfRegion } from '../../../store/Vanlifer/regionSlice';
import { Compound } from '../../../services/restserver-openapi';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Grid, Typography, Button, Card, CardMedia, CardContent } from '@mui/material';
import {Link, useParams} from 'react-router-dom';
import {useAppDispatch, useAppSelector} from "../../../hooks/hooks";
import Container from '../../../components/Container';

type TopCompoundsProps = {
    regionId: number;
};

const TopCompoundsByRegion = ({ regionId }: TopCompoundsProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const mode = theme.palette.mode;
    const isMd = useMediaQuery(theme.breakpoints.up('md'), { defaultMatches: true });

    const [compounds, setCompounds] = useState<Compound[]>([]);

    useEffect(() => {
        dispatch(fetchAllRegions()).then(() => {
            dispatch(setRegionById(Number(regionId)));
            setCompounds([]);
            dispatch(fetchCompoundsOfRegion(Number(regionId)))
                .then(unwrapResult)
                .then((fetchedCompounds: Compound[]) => {
                    const compoundsVisible = fetchedCompounds.filter(compound => compound.visibleCompound);
                    const compoundsSorted = [...compoundsVisible].sort((a, b) => {
                        if (a.imageIds && a.imageIds.length > 0 && (!b.imageIds || b.imageIds.length === 0)) {
                            return -1;
                        }
                        if (b.imageIds && b.imageIds.length > 0 && (!a.imageIds || a.imageIds.length === 0)) {
                            return 1;
                        }
                        return 0.5 - Math.random();
                    });

                    setCompounds(compoundsSorted);
                });
        });
    }, [dispatch, regionId]);

    // 🏆 Featured-Compounds filtern & nach `overallRating` sortieren
    const featuredCompounds = compounds
        .filter(compound => compound.featured)
        .sort((a, b) => (b.overallRating ?? 0) - (a.overallRating ?? 0))
        .slice(0, 4);

    return (
        <Container>
        <Grid container spacing={4}>
            <Grid item xs={12}>
                <Box marginBottom={2}>
                    <Typography
                        sx={{
                            textTransform: 'uppercase',
                            fontWeight: 'medium',
                        }}
                        gutterBottom
                        color={'text.secondary'}
                    >
                        Inspiration
                    </Typography>
                    <Typography
                        variant="h4"
                        color="text.primary"
                        sx={{ fontWeight: 700 }}
                    >
                        Unsere {' '}
                        <Typography
                            color={'text.primary'}
                            component={'span'}
                            variant={'inherit'}
                            sx={{
                                background: `linear-gradient(180deg, transparent 82%, ${alpha(
                                    mode === 'light' ? theme.palette.secondary.main : theme.palette.primary.main,
                                    0.3,
                                )} 0%)`,
                            }}
                        >
                            Top-4-Gastgeber
                        </Typography>
                        {' '} in der Region "Durch die Innerschweiz"
                    </Typography>
                </Box>
            </Grid>

            {/* Empfehlungskarten im 2x2-Grid */}
            <Grid item container xs={12} md={6} spacing={2}>
                {featuredCompounds.length > 0 ? (
                    <Grid container spacing={2}>
                        {featuredCompounds.map((compound) => (
                            <Grid item xs={12} sm={6} md={6} key={compound.compoundId}>
                                <Card
                                    sx={{
                                        boxShadow: 2,
                                        backgroundColor: 'background.paper',
                                        textDecoration: 'none',
                                        transition: 'all .2s ease-in-out',
                                        '&:hover': { transform: `translateY(-${theme.spacing(0.5)})` },
                                    }}
                                    component={Link}
                                    to={`/vanlifer/Campsite/${compound.compoundId}`}
                                >
                                    {compound.imageIds?.length! > 0 ? (
                                        <CardMedia
                                            component="img"
                                            height={200}
                                            image={`${process.env.REACT_APP_API_URL}/image/${compound.imageIds![0]}`}
                                            alt={compound.name}
                                            sx={{ objectFit: 'cover' }}
                                        />
                                    ) : (
                                        <Box
                                            height={200}
                                            display="flex"
                                            alignItems="center"
                                            justifyContent="center"
                                            sx={{ backgroundColor: theme.palette.grey[300] }}
                                        >
                                            <Typography variant="body2" color="text.secondary">
                                                Kein Bild verfügbar
                                            </Typography>
                                        </Box>
                                    )}
                                    <CardContent>
                                        <Typography variant="h6">{compound.name}</Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {compound.city}, {compound.country}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Typography variant="body1" color="text.secondary">
                        Keine empfohlenen Stellplätze in dieser Region gefunden.
                    </Typography>
                )}
            </Grid>

            {/* Info & Call-to-Action */}
            <Grid item xs={12} md={6} alignItems="center">
                <Box>
                     <Box marginBottom={3}>
                        <Typography variant="h6" component="p" color="text.secondary">
                            Hier siehst du unsere Top-Empfehlungen für Stellplätze in dieser Region.
                            Diese wurden anhand ihrer Beliebtheit und Bewertungen ausgewählt.
                            Wähle einen Platz aus, um mehr Details zu erfahren und deine Reise zu planen!
                        </Typography>
                    </Box>
                    {/*<Box*/}
                    {/*    display="flex"*/}
                    {/*    flexDirection={{ xs: 'column', sm: 'row' }}*/}
                    {/*    alignItems={{ xs: 'stretched', sm: 'flex-start' }}*/}
                    {/*>*/}
                    {/*    <Button*/}
                    {/*        variant="contained"*/}
                    {/*        color="primary"*/}
                    {/*        size="large"*/}
                    {/*        component={Link}*/}
                    {/*        to={"/login"}*/}
                    {/*        fullWidth={!isMd}*/}
                    {/*    >*/}
                    {/*        Mehr Gastgeber kennenlernen...*/}
                    {/*    </Button>*/}
                    {/*</Box>*/}
                </Box>
            </Grid>
        </Grid>
        </Container>
    );
};

export default TopCompoundsByRegion;
