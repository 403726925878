import {
    Box,
    Dialog,
    DialogContent,
    Card,
    DialogTitle,
    IconButton,
} from "@mui/material";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

type OfferImagesProp = {
    imageIds: number[];
    altText?: string;
    showLargeImage?: boolean;
};

export default function OfferImages({ imageIds, altText, showLargeImage }: OfferImagesProp) {
    const [open, setOpen] = React.useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = React.useState<number | null>(null);

    const handleClickOpen = (index: number) => {
        setSelectedImageIndex(index);
        setOpen(true);
    };

    const handleClose = () => {
        setSelectedImageIndex(null);
        setOpen(false);
    };

    const handleNext = () => {
        if (selectedImageIndex !== null) {
            const nextIndex = (selectedImageIndex + 1) % imageIds.length;
            setSelectedImageIndex(nextIndex);
        }
    };

    const handlePrevious = () => {
        if (selectedImageIndex !== null) {
            const previousIndex = (selectedImageIndex - 1 + imageIds.length) % imageIds.length;
            setSelectedImageIndex(previousIndex);
        }
    };

    // Falls das große Bild angezeigt wird, sollen die Thumbnails ab Index 1 starten
    const thumbnails = showLargeImage ? imageIds.slice(1) : imageIds;

    return (
        <Card sx={{ boxShadow: 0, padding: 2, backgroundColor: "#FFFFFF" }}>
            {/* Großes Bild */}
            {showLargeImage && imageIds.length > 0 && (

                <Box
                    sx={{cursor: "pointer", marginBottom: 2}}
                    onClick={() => handleClickOpen(0)}
                >
                    <img
                        src={`${process.env.REACT_APP_API_URL}/image/${imageIds[0]}`}
                        alt={altText}
                        style={{
                            width: '100%',
                            height: 'auto',
                            objectFit: 'cover',
                            borderRadius: 5
                        }}
                    />
                </Box>
            )}

            {/* Thumbnails */}
            <Box
                sx={{
                    display: "flex",
                    overflowX: "auto",
                    gap: 1,
                    padding: 1,
                    paddingBottom: 1,
                    borderRadius: 2,
                }}
            >
                {thumbnails.map((imageId, i) => (
                    <Box
                        key={imageId}
                        sx={{
                            flex: "0 0 auto",
                            width: 100,
                            cursor: "pointer",
                        }}
                        onClick={() => handleClickOpen(showLargeImage ? i + 1 : i)}
                    >
                        <img
                            src={`${process.env.REACT_APP_API_URL}/image/${imageId}`}
                            alt={`Bild ${i}`}
                            style={{
                                width: "100%",
                                height: "auto",
                                borderRadius: 5,
                            }}
                        />
                    </Box>
                ))}
            </Box>

            {/* Dialog mit großem Bild */}
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>{altText}</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    {selectedImageIndex !== null && (
                        <img
                            src={`${process.env.REACT_APP_API_URL}/image/${imageIds[selectedImageIndex]}`}
                            alt={altText}
                            style={{ width: "99%", borderRadius: 5 }}
                        />
                    )}
                </DialogContent>
                <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: 2, px: 2 }}>
                    <IconButton onClick={handlePrevious}>
                        <NavigateBeforeIcon />
                    </IconButton>
                    <IconButton onClick={handleNext}>
                        <NavigateNextIcon />
                    </IconButton>
                </Box>
            </Dialog>
        </Card>
    );
}
