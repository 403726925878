import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
  useTheme,
  Alert,
  AlertTitle
} from '@mui/material';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import SiteForm from '../../components/hostComponents/SiteForm';
import SiteInformation from '../../components/hostComponents/SiteInformation';
import ManagementCalendar from '../../components/hostComponents/ManagementCalendar';
import ManagementCalendarOverview from '../../components/hostComponents/ManagementCalendarOverview';
import LoadingPage from '../../components/presentationalComponents/LoadingPage';
import ErrorHandling from '../../components/presentationalComponents/ErrorHandling';
import AvailabilityForm from '../../components/hostComponents/AvailabilityForm';

import { useAppDispatch, useAppSelector } from '../../hooks/hooks';
import {
  defaultSite,
  deleteSelectedSite,
  fetchAllSites,
  getTotalOwnedSites,
  resetSiteErrorState,
  setSite
} from '../../store/Host/sitesManagement/siteSlice';

import { fetchAllSizes } from '../../store/Host/sitesManagement/sizesSlice';
import {
  fetchAllAvailabilities,
  resetAvailabilitiesErrorState
} from '../../store/Host/sitesManagement/availabilitySlice';

import { getReadableSiteType } from '../../utils/typeUtils';
import {Compound} from "../../services/restserver-openapi";


// --- Type Definitions ---
type OverviewSectionProps = {
  selectedCompound: Compound;
  availabilities: any[];
  onBack: () => void;
};

type SiteDetailsSectionProps = {
  site: any;
  sites: any[];
  showSiteForm: boolean;
  isCreatingSite: boolean;
  compoundBelongsToHost: boolean;
  canAddMoreSites: boolean;
  onBack: () => void;
  handleSiteSelection: (event: SelectChangeEvent<number>) => void;
  handleAddSite: () => void;
  handleEditSite: () => void;
  handleOpenDeleteDialog: () => void;
  DeleteDialog: React.FC;
  availabilities: any[];
};

type NoCompoundSelectedProps = {
  theme: any;
};

// --- Übersichtskomponente ---
const OverviewSection: React.FC<OverviewSectionProps> = ({ selectedCompound, availabilities, onBack }) => (
    <Container sx={{ minHeight: '100vh', py: 4 }}>
      <Paper sx={{ borderRadius: 5, p: 4 }}>
        <Grid container spacing={2} justifyContent="space-between" padding={4}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item xs={10}>
                <Typography variant="h4">
                  Angebote und Verfügbarkeiten des Betriebs {selectedCompound.name}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ width: '150px', float: 'left', marginBottom: '60px' }}
                    onClick={onBack}
                >
                  Zurück
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ManagementCalendarOverview availabilities={availabilities} />
          </Grid>
        </Grid>
      </Paper>
    </Container>
);

// --- Kein Betrieb ausgewählt ---
const NoCompoundSelected: React.FC<NoCompoundSelectedProps> = ({ theme }) => (
    <Box sx={{ minHeight: '100vh', backgroundColor: theme.palette.alternate.dark }} padding={2}>
      <Paper sx={{ backgroundColor: theme.palette.alternate.main, padding: '20px' }}>
        <Alert severity="warning">
          <AlertTitle>Du musst einen Betrieb auswählen</AlertTitle>
          <p>Um deine Verfügbarkeiten zu managen, musst du zuerst einen Betrieb erstellt haben.</p>
          <p>Nutze den Button oben rechts, um einen Betrieb auszuwählen.</p>
        </Alert>
      </Paper>
    </Box>
);

// --- Ladezustand ---
const LoadingScreen: React.FC = () => (
    <div style={{ minHeight: '100vh' }}>
      <LoadingPage />
    </div>
);

const SiteDetailsSection: React.FC<SiteDetailsSectionProps> = ({
                                                                 site,
                                                                 sites,
                                                                 showSiteForm,
                                                                 isCreatingSite,
                                                                 compoundBelongsToHost,
                                                                 canAddMoreSites,
                                                                 onBack,
                                                                 handleSiteSelection,
                                                                 handleAddSite,
                                                                 handleEditSite,
                                                                 handleOpenDeleteDialog,
                                                                 DeleteDialog,
                                                                 availabilities
                                                               }) => {
  return (
      <Container sx={{ minHeight: '100vh', py: 4 }}>
          {sites.length !== 0 ? (
              <Paper sx={{ borderRadius: 5, p: 4 }}>
              <Grid container spacing={2} justifyContent="space-between">
                {site?.siteId ? (
                    <Grid item xs={12}>
                      <Grid container my={3} justifyContent="space-between">
                        <Grid item xs={6} sm={4} md={2}>
                          <Button
                              variant="contained"
                              color="primary"
                              sx={{ width: '150px', float: 'left', marginBottom: '60px' }}
                              onClick={onBack}
                          >
                            Übersicht
                          </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={2}>
                          <FormControl fullWidth>
                            <InputLabel id="select-site">Angebot auswählen</InputLabel>
                            <Select
                                labelId="select-site"
                                id="select-site"
                                label="Angebot auswählen"
                                value={site.siteId || ''}
                                onChange={handleSiteSelection}
                            >
                              {sites.map((item) => (
                                  <MenuItem key={item.siteId} value={item.siteId}>
                                    {item.label}
                                  </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item>
                          {!showSiteForm && (
                              <Tooltip
                                  title={
                                    !compoundBelongsToHost
                                        ? 'Keine Berechtigung zum Hinzufügen'
                                        : !canAddMoreSites
                                            ? 'Limit erreicht. Add-on nötig.'
                                            : ''
                                  }
                              >
                                <Stack>
                                  <IconButton
                                      color="primary"
                                      aria-label="add new site"
                                      onClick={handleAddSite}
                                      disabled={!compoundBelongsToHost || !canAddMoreSites}
                                  >
                                    <Typography variant="body2">Angebot hinzufügen</Typography>
                                    <AddCircleIcon sx={{ fontSize: 40 }} />
                                  </IconButton>
                                </Stack>
                              </Tooltip>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                ) : (
                    <Grid item xs={12} md={4}>
                      <Typography variant="h4">Neues Angebot erstellen</Typography>
                    </Grid>
                )}

                {!isCreatingSite && (
                    <Grid item xs={12}>
                      <Typography variant="h5" mb={4}>
                        Verfügbarkeiten für den gewählten {getReadableSiteType(site)}
                      </Typography>
                      <AvailabilityForm availabilities={availabilities} />
                      <ManagementCalendar availabilities={availabilities} />
                    </Grid>
                )}

                <Grid item xs={12} sm={6}>
                  <Typography variant="h5">
                    Informationen zum gewählten {getReadableSiteType(site)}
                  </Typography>
                </Grid>

                {showSiteForm ? (
                    <Grid item xs={12} md={3}>
                      <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
                        <Grid item>
                          <IconButton color="primary" aria-label="close form" onClick={onBack}>
                            <CancelIcon sx={{ fontSize: 30 }} />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <Button variant="outlined" color="secondary" onClick={onBack}>
                            Abbrechen
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                ) : (
                    <Grid item xs={12} sm={6}>
                      <Grid container justifyContent="flex-end" alignItems="center">
                        <Grid item>
                          <Tooltip title={!compoundBelongsToHost ? 'Keine Bearbeitungsrechte' : ''}>
                      <span>
                        <IconButton
                            color="primary"
                            aria-label="edit site"
                            onClick={handleEditSite}
                            disabled={!compoundBelongsToHost}
                        >
                          <EditIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      </span>
                          </Tooltip>
                        </Grid>
                        <Grid item>
                          <Tooltip title={!compoundBelongsToHost ? 'Keine Löschrechte' : ''}>
                      <span>
                        <IconButton
                            color="primary"
                            aria-label="delete site"
                            onClick={handleOpenDeleteDialog}
                            disabled={!compoundBelongsToHost}
                        >
                          <DeleteIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      </span>
                          </Tooltip>
                          <DeleteDialog />
                        </Grid>
                      </Grid>
                    </Grid>
                )}

                <Grid item xs={12}>
                  {showSiteForm ? (
                      <>
                        <SiteForm onBack={onBack} />
                        <Box mt={2}>
                          <Button variant="outlined" color="secondary" onClick={onBack}>
                            Abbrechen
                          </Button>
                        </Box>
                      </>
                  ) : (
                      <SiteInformation />
                  )}
                </Grid>
              </Grid>
              </Paper>
          ) : (
              <Paper sx={{ borderRadius: 5, p: 4 }}>
              <Grid container my={3} spacing={2}>
                <Grid item xs={10}>
                  <Typography variant="h6">
                    Keine Angebote vorhanden. Erstelle dein erstes Angebot!
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <IconButton color="primary" aria-label="add new site" onClick={handleAddSite}>
                    <AddCircleIcon sx={{ fontSize: 40 }} />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  {showSiteForm && <SiteForm onBack={onBack} />}
                </Grid>
              </Grid>
              </Paper>
          )}
      </Container>
  );
};

const SitesManagement = () => {
  const theme = useTheme();
  const sites = useAppSelector(state => state.site.sites);
  const availabilities = useAppSelector(state => state.availability.availabilities);
  const errorSiteState = useAppSelector(state => state.site.error);
  const errorAvailabilityState = useAppSelector(state => state.availability.error);
  const site = useAppSelector(state => state.site.site);
  const [showSiteForm, setShowSiteForm] = useState(false);
  const dispatch = useAppDispatch();
  const [isCreatingSite, setIsCreatingSite] = useState(false);
  const loading = useAppSelector(state => state.site.loading);
  const hostId = useAppSelector(state => state.login.userSpecificationId);
  const selectedCompound = useAppSelector(state => state.compounds.selectedCompound);
  const compoundId = selectedCompound?.compoundId;
  const [overview, setOverview] = useState(false);

  const compoundBelongsToHost = Number(selectedCompound?.hostId) === Number(hostId);
  const totalOwnedSites = useAppSelector(state => state.site.totalOwnedSites);
  const userEntitlements = useAppSelector(state => state.user.entitlements);

  const canAddMoreSites = useMemo(() => {
    const entitlement = userEntitlements.find(e => e.featureId === 'hostownednumberofsites')?.value;
    return entitlement === 'Unlimited' || totalOwnedSites < Number(entitlement);
  }, [userEntitlements, totalOwnedSites]);

  useEffect(() => {
    if (compoundId) {
      dispatch(fetchAllSites(compoundId)).unwrap().then((payload) => {
        if (payload) {
          dispatch(setSite(payload[0]));
          dispatch(fetchAllSizes());
          dispatch(getTotalOwnedSites());
        }
      });
    }
  }, [dispatch, compoundId]);

  useEffect(() => {
    dispatch(resetSiteErrorState());
    dispatch(resetAvailabilitiesErrorState());
  }, [dispatch]);

  useEffect(() => {
    if (site?.offer?.offerId) {
      dispatch(fetchAllAvailabilities(site.offer.offerId));
    }
  }, [dispatch, site]);

  const handleAddSite = async () => {
    setIsCreatingSite(true);
    if (compoundId) {
      dispatch(setSite({ compoundId, ...defaultSite, offer: { ...defaultSite.offer, hostId } }));
    }
    setShowSiteForm(true);
  };

  const handleEditSite = () => {
    setShowSiteForm(prev => !prev);
  };

  const handleDeleteSite = async () => {
    if (site?.siteId && compoundId) {
      await dispatch(deleteSelectedSite(site.siteId));
      const payload = await dispatch(fetchAllSites(compoundId)).unwrap();
      if (payload) {
        dispatch(setSite(payload[0]));
      }
      await dispatch(getTotalOwnedSites());
    }
    handleCloseDeleteDialog();
  };

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const handleOpenDeleteDialog = () => setOpenDeleteDialog(true);
  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false);

  const DeleteDialog = () => (
      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Unterkunft löschen?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Möchtest du die Unterkunft wirklich löschen? Diese Aktion kann nicht rückgängig gemacht werden.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} variant="outlined">Abbrechen</Button>
          <Button onClick={handleDeleteSite} color="primary" autoFocus variant="contained">Löschen</Button>
        </DialogActions>
      </Dialog>
  );

  const handleSiteSelection = (event: SelectChangeEvent<number>) => {
    if (showSiteForm) setShowSiteForm(false);
    const id = event.target.value;
    const found = sites.find((item) => item.siteId === +id);
    if (found) dispatch(setSite(found));
  };

  const onBack = async () => {
    setShowSiteForm(false);
    setIsCreatingSite(false);
    if (compoundId) {
      const payload = await dispatch(fetchAllSites(compoundId)).unwrap();
      if (payload && (!site || !payload.some(item => item.siteId === site.siteId))) {
        dispatch(setSite(payload[0]));
      }
    }
  };

  if (!compoundId) return <NoCompoundSelected theme={theme} />;
  if (loading) return <LoadingScreen />;
  if (overview) return (
      <OverviewSection
          selectedCompound={selectedCompound}
          availabilities={availabilities}
          onBack={() => setOverview(false)}
      />
  );

  return (
      <SiteDetailsSection
          site={site}
          sites={sites}
          showSiteForm={showSiteForm}
          isCreatingSite={isCreatingSite}
          compoundBelongsToHost={compoundBelongsToHost}
          canAddMoreSites={canAddMoreSites}
          onBack={onBack}
          handleSiteSelection={handleSiteSelection}
          handleAddSite={handleAddSite}
          handleEditSite={handleEditSite}
          handleOpenDeleteDialog={handleOpenDeleteDialog}
          DeleteDialog={DeleteDialog}
          availabilities={availabilities}
      />
  );
};

export default SitesManagement;
