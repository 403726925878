import React from "react";
import {
    Card,
    CardContent,
    Typography,
    Avatar,
    Button,
    Box,
} from "@mui/material";

type YouTubeCardType = "video" | "channel";

interface YouTubeCardProps {
    type: YouTubeCardType;
    title: string;
    description: string;

    videoId?: string;

    channelName?: string;
    channelUrl?: string;
    channelAvatarUrl?: string;
    channelBannerUrl?: string;
}

const YouTubeCard: React.FC<YouTubeCardProps> = (props) => {
    const {
        type,
        title,
        description,
        videoId,
        channelName = "Unbekannter Kanal",
        channelUrl,
        channelAvatarUrl,
        channelBannerUrl,
    } = props;

    const renderAvatar = () => {
        if (channelAvatarUrl) {
            return (
                <Avatar
                    src={channelAvatarUrl}
                    sx={{
                        width: 80,
                        height: 80,
                        border: "3px solid white",
                        boxShadow: 2,
                    }}
                />
            );
        }

        const initials = channelName
            .split(" ")
            .map((w) => w[0])
            .join("")
            .toUpperCase()
            .slice(0, 2);

        return (
            <Avatar
                sx={{
                    width: 80,
                    height: 80,
                    bgcolor: "grey.300",
                    color: "text.primary",
                    fontWeight: "bold",
                    border: "3px solid white",
                    boxShadow: 2,
                }}
            >
                {initials}
            </Avatar>
        );
    };

    return (
        <Card
            sx={{
                width: "100%",
                maxWidth: 400,
                minHeight: 500,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                position: "relative",
            }}
        >
            {/* Header */}
            {type === "video" && videoId && (
                <Box sx={{ position: "relative", paddingTop: "56.25%" }}>
                    <iframe
                        src={`https://www.youtube.com/embed/${videoId}`}
                        title={title}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            border: "none",
                        }}
                    ></iframe>
                </Box>
            )}

            {type === "channel" && (
                <Box sx={{ position: "relative", height: 160, backgroundColor: "#eee" }}>
                    {channelBannerUrl ? (
                        <img
                            src={channelBannerUrl}
                            alt="Kanalbanner"
                            style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        />
                    ) : (
                        <Box
                            sx={{
                                width: "100%",
                                height: "100%",
                                background: "linear-gradient(to right, #ccc, #ddd)",
                            }}
                        />
                    )}

                    {/* Avatar über Banner */}
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: -40,
                            left: "50%",
                            transform: "translateX(-50%)",
                        }}
                    >
                        {renderAvatar()}
                    </Box>
                </Box>
            )}

            {/* Content */}
            <CardContent
                sx={{
                    textAlign: "center",
                    paddingTop: type === "channel" ? "56px" : "16px",
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Typography variant="h6" gutterBottom>
                    {type === "channel" ? channelName : title}
                </Typography>

                {type === "channel" && (
                    <>
                        {channelUrl ? (
                            <Button
                                variant="contained"
                                href={channelUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                sx={{
                                    backgroundColor: "#FF0000",
                                    "&:hover": { backgroundColor: "#cc0000" },
                                    textTransform: "none",
                                    fontSize: "0.8rem",
                                    mt: 1,
                                }}
                            >
                                Zum Kanal
                            </Button>
                        ) : (
                            <Typography variant="body2" color="textSecondary">
                                Kein Link verfügbar
                            </Typography>
                        )}
                    </>
                )}

                <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ mt: 2, flexGrow: 1 }}
                >
                    {description}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default YouTubeCard;
