import React, {useEffect} from 'react';
import { Grid, Box, Typography } from '@mui/material';
import TourCard from '../TourCard';
import { useAppSelector } from '../../../hooks/hooks';
import LoadingPage from '../../presentationalComponents/LoadingPage';
import Container from '../../../components/Container';
import {TourOverview} from "../../../services/restserver-openapi";

interface SuggestedToursProps {
  generatedTours: TourOverview[] | undefined;
}
function SuggestedTours({ generatedTours }: SuggestedToursProps) {
  const isLoading = useAppSelector(state => state.tour.loading);

  const iterateThroughStays = (stays: any): string[] => {
    let waypoints: string[] = [];
    stays.forEach((stay: any) => {
      waypoints.push(`${stay.coordinates.x},${stay.coordinates.y}`);
    });
    return waypoints;
  };

  useEffect(() => {
  }, [generatedTours, isLoading]);

  return (
    <Container>
      {isLoading ? (<LoadingPage />) : (
        <Box>
          <Box marginBottom={4}>
            <Typography
                sx={{
                  textTransform: 'uppercase',
                  fontWeight: 'medium',
                }}
                gutterBottom
                color={'text.secondary'}
            >
              Individuell für dich
            </Typography>
            <Typography variant={'h4'} sx={{ fontWeight: 700 }} align={'left'}>
              Hier sind einige Reisevorschläge für deine Lieblingsregion
            </Typography>
            <Typography
              variant="h6"
              component="p"
              color="text.secondary"
              align={'left'}
            >
              Alle Reisen sind direkt buchbar. Klick auf eine Tour damit Du die Details sehen und bearbeiten kannst.
            </Typography>
          </Box>
          <Grid container spacing={4}>
            {/*<Grid item xs={12} sm={12} md={12} xl={12}*/}
            {/*      sx={{...vanliferOverviewStyling.gridItem}}>*/}
            {/*    <Typography variant="h5"> Hier sind unsere Vorschläge für deine*/}
            {/*        Reise<*/}
            {/*    /Typography>*/}
            {/*</Grid>*/}
            {generatedTours?.map((tour) => (
              <Grid item xs={12} sm={6} md={4} key={tour.tourId}>
                {tour.tourId && tour.stays && tour.stays.length >= 1 &&
                  <TourCard tour={tour} waypoints={iterateThroughStays(tour.stays)} />
                }
              </Grid>
            ))}
          </Grid>
        </Box>)}
    </Container>
  )
    ;
}

export default SuggestedTours;
