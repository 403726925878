import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { alpha, useTheme } from '@mui/material/styles';
import imgSlider2 from '../../assets/Images/hostCampsite.jpg';
import { Link as RouterLink } from 'react-router-dom';
import Container from '../Container';
import { PaletteMode } from '@mui/material';
import { useAppSelector } from '../../hooks/hooks';

const HostWelcomeHeader = (): JSX.Element => {
  const theme = useTheme();
  const person = useAppSelector((state) => state.user.user.person);
  const compoundId = useAppSelector(state => state.compounds.selectedCompound?.compoundId);
  const mode = theme.palette.mode;
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const LeftSide = () => (
    <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
      <Typography
        sx={{
          textTransform: 'uppercase',
          fontWeight: 'medium',
        }}
        gutterBottom
        color={'text.secondary'}
      >
        Willkommen
      </Typography>
      <Box marginBottom={2}>
        <Typography
          variant="h2"
          color="text.primary"
          sx={{
            fontWeight: 700,
          }}
        >
          Hallo {person?.firstname},{' '}
          <br />
          <Typography
            color={'text.primary'}
            component={'span'}
            variant={'inherit'}
            sx={{
              background: `linear-gradient(180deg, transparent 82%, ${alpha(
                mode === 'light' as PaletteMode ? theme.palette.secondary.main : theme.palette.primary.main,
                0.3,
              )} 0%)`,
            }}
          >
            schön bist du hier!
          </Typography>
        </Typography>
      </Box>
      <Box marginBottom={3}>
        <Typography variant="h6" component="p" color="text.secondary">
          Es freut uns sehr, Dich als Gastgeber bei Vanlife Travel begrüßen zu dürfen. Wir möchten Dir die Verwaltung
          Deiner Unterkünfte vereinfachen und Dir helfen, das Beste aus Deiner Gastgeberrolle herauszuholen. Solltest Du
          Fragen haben oder Unterstützung benötigen, steht unser Support-Team jederzeit gerne zur Verfügung. Wir
          wünschen Dir viel Erfolg und freuen uns auf eine erfolgreiche Zusammenarbeit!
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'stretched', sm: 'flex-start' }}
        gap={'1rem'}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          component={RouterLink}
          to={'/host/SitesManagement'}
          fullWidth={!isMd}
        >
          Verwalte Deine Buchungen
        </Button>
        <Button
            variant="outlined"
            color="primary"
            size="large"
            component={RouterLink}
            to={'/host/Compounds'}
            fullWidth={!isMd}
          >
            Pflege Dein Angebot
          </Button>
      </Box>
    </Box>
  );

  const RightSide = (): JSX.Element => {
    return (
      <Box
        sx={{
          height: { xs: 'auto', md: 1 },
          '& .slick-slide img': {
            objectFit: 'cover',
          },
          '& .slick-list, & .slick-slider, & .slick-track, & .slick-slide > div': {
            height: { xs: 'auto', md: 1 },
          },
        }}
      >
        <Box
          component={'img'}
          loading="lazy"
          src={imgSlider2.toString()}
          height={{ xs: 'auto', md: 1 }}
          maxHeight={{ xs: 500, md: 1 }}
          style={{ objectFit: 'cover' }}
          width={1}
          maxWidth={1}
        />
      </Box>

    );
  };

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    >
      <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row' }}
          position={'relative'}
          minHeight={{ xs: 'auto', md: 600 }}
        >
          <Box
            width={1}
            order={{ xs: 2, md: 1 }}
            display={'flex'}
            alignItems={'center'}
          >
            <Container>
              <LeftSide />
            </Container>
          </Box>
          <Box
            sx={{
              flex: { xs: '0 0 100%', md: '0 0 50%' },
              position: 'relative',
              maxWidth: { xs: '100%', md: '50%' },
              order: { xs: 1, md: 2 },
            }}
          >
            <Box
              sx={{
                width: { xs: 1, md: '50vw' },
                height: '100%',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    overflow: 'hidden',
                    left: '0%',
                    width: 1,
                    height: 1,
                    position: { xs: 'relative', md: 'absolute' },
                    clipPath: {
                      xs: 'none',
                      md: 'polygon(10% 0%, 100% 0, 100% 100%, 10% 100%, 0% 50%)',
                    },
                    shapeOutside: {
                      xs: 'none',
                      md: 'polygon(10% 0%, 100% 0, 100% 100%, 0% 100%, 0% 50%)',
                    },
                  }}
                >
                  <RightSide />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default HostWelcomeHeader;
