import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "../../hooks/hooks";
import {
    deleteSelectedUser,
    getAsyncSpecificationId,
    getEntitlementsForSelectedUser,
    getMaxAllowedSitesWithoutAddonForSelectedHost,
    getSubscriptionsForSelectedUser,
    getTotalOwnedSitesForSelectedHost,
    getUserDetails,
    resetAgentUserDetail
} from "../../store/Agent/agentUserDetailSlice";
import { User } from '../../services/restserver-openapi';
import { Main } from "../../layouts";
import Container from "../../components/Container";
import {
    Alert,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Link,
    Snackbar,
    Typography,
    Divider,
    IconButton, Paper
} from "@mui/material";
import { ArrowBackIosNewRounded, Delete as DeleteIcon } from "@mui/icons-material";
import HostDetails from "../../components/agentComponents/HostDetails";
import VanliferDetails from "../../components/agentComponents/VanliferDetails";
import TravelAgentDetails from "../../components/agentComponents/TravelAgentDetails";
import AboOverview from "../../components/profileComponents/AboOverview";

const AgentUserDetailPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { userRole, userId } = useParams<{ userRole: string, userId: string }>();
    const selectedUser = useAppSelector(state => state.agentUserDetail.selectedUser);
    const specificationId = useAppSelector(state => state.agentUserDetail.specificationId);
    const subscriptions = useAppSelector(state => state.agentUserDetail.subscriptions);
    const totalOwnedSites = useAppSelector(state => state.agentUserDetail.selectedHost.totalOwnedSites);
    const maxAllowedSitesWithoutAddon = useAppSelector(state => state.agentUserDetail.selectedHost.maxAllowedSitesWithoutAddon);
    const userEntitlements = useAppSelector(state => state.agentUserDetail.userEntitlements);
    const isVerifiedHost = useAppSelector(state => state.agentUserDetail.selectedHost.isVerified);
    const isVerifiedAgent = useAppSelector(state => state.agentUserDetail.selectedAgent.isVerified);
    const loggedInUserIsVerifiedAgent = useAppSelector(state => state.user.isVerifiedAgent);

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [errorSnackbarMessage, setErrorSnackbarMessage] = useState("");

    const UserSpecificComponent = () => {
        switch (userRole) {
            case User.userRole.HOST:
                return <>
                        <Typography variant={'caption'} color={'text.secondary'} fontWeight={700} sx={{ textTransform: 'uppercase' }}>Compounds</Typography>
                        <HostDetails user={selectedUser} hostId={specificationId} totalSites={totalOwnedSites} isVerified={isVerifiedHost} />
                    </>;
            case User.userRole.VANLIFER:
                return <>
                        <VanliferDetails user={selectedUser} vanliferId={specificationId} />
                    </>;
            case User.userRole.AGENT:
                return <TravelAgentDetails user={selectedUser} agentId={specificationId} isVerified={isVerifiedAgent} />;
            default:
                return <div>Invalid user role</div>;
        }
    }

    useEffect(() => {
        if (userRole && userId) {
            dispatch(getAsyncSpecificationId({ userId: parseInt(userId), userRoleInput: userRole as User.userRole }))
                .unwrap()
                .then(payload => {
                    dispatch(getUserDetails({ userSpecificationId: payload, userRoleInput: userRole }));
                    dispatch(getSubscriptionsForSelectedUser({ userId: parseInt(userId) }));
                    dispatch(getEntitlementsForSelectedUser({ userId: parseInt(userId) }));
                    if (userRole === User.userRole.HOST) {
                        dispatch(getTotalOwnedSitesForSelectedHost({ hostId: parseInt(userId) }));
                        dispatch(getMaxAllowedSitesWithoutAddonForSelectedHost({ hostId: parseInt(userId) }));
                    }
                });
        }
    }, [dispatch, userId, userRole]);

    const goBack = () => {
        dispatch(resetAgentUserDetail());
        navigate(-1);
    };

    const handleDelete = async () => {
        if (userRole && specificationId) {
            try {
                const resultAction = await dispatch(deleteSelectedUser({
                    specificationId,
                    userRole: userRole as User.userRole
                }));

                if (deleteSelectedUser.fulfilled.match(resultAction)) {
                    goBack(); // alles gut
                } else {
                    // Optional: detaillierter Fehlertext, falls vorhanden
                    const errorMessage =
                        resultAction?.payload?.toString() ||
                        resultAction?.error?.message ||
                        "Fehler beim Löschen des Users";

                    setErrorSnackbarMessage(errorMessage);
                    setOpenErrorSnackbar(true);
                }
            } catch (error) {
                // Catch für unerwartete Fehler
                setErrorSnackbarMessage("Ein unerwarteter Fehler ist aufgetreten.");
                setOpenErrorSnackbar(true);
            } finally {
                setOpenDeleteDialog(false);
            }
        }
    };


// Alte Version ohne Error-Handling
    // const handleDelete = async () => {
    //     if (userRole && specificationId) {
    //         dispatch(deleteSelectedUser({  specificationId: specificationId, userRole: userRole as User.userRole }))
    //             .then(resultAction => {
    //                 if (resultAction.meta.requestStatus === 'fulfilled') {
    //                     goBack();
    //                 } else {
    //                     setErrorSnackbarMessage("Fehler beim Löschen des Users");
    //                     setOpenErrorSnackbar(true);
    //                 }
    //                 setOpenDeleteDialog(false);
    //             });
    //     }
    // };

    if (!loggedInUserIsVerifiedAgent) {
        return (
            <Main>
                <Container>
                    <Alert severity="error">Sie haben keine Agent-Berechtigung.</Alert>
                </Container>
            </Main>
        );
    }

    return (
        <Main>
            <Snackbar open={openErrorSnackbar} autoHideDuration={6000} onClose={() => setOpenErrorSnackbar(false)}>
                <Alert onClose={() => setOpenErrorSnackbar(false)} severity="error">{errorSnackbarMessage}</Alert>
            </Snackbar>
            <Container>
                <Box display="flex" alignItems="center" mb={2}>
                    <IconButton onClick={goBack} size="large">
                        <ArrowBackIosNewRounded />
                    </IconButton>
                    <Typography variant="h5" ml={2} fontWeight={600}>
                        {selectedUser.person?.lastname}, {selectedUser.person?.firstname} ({userRole})
                    </Typography>
                </Box>
                <Divider sx={{ my: 2 }} />
                    <Typography variant={'h6'} color={'text.secondary'} fontWeight={700} sx={{ textTransform: 'uppercase' }}>Userdaten & Kontakt</Typography>
                    <Grid container spacing={3} sx={{mt: '.1rem'}} >
                        <Grid item xs={12} md={6} display="flex" sx={{flex: 1}}>
                            <Paper sx={{backgroundColor: 'secondary.light', padding: 4, borderRadius: 5, width: '100%'}}>

                                <Typography variant="body1">User ID: {selectedUser.userId}</Typography>
                                <Typography variant="body1">CommunityName: {selectedUser.communityName}</Typography>
                                <Typography variant="body1">Chargebee ID:
                                    <Link href={`https://${process.env.REACT_APP_CHARGEBEE_SITE}.chargebee.com/d/customers/${selectedUser.chargebeeId}`} target="_blank" rel="noopener noreferrer">{selectedUser.chargebeeId}
                                    </Link>
                                </Typography>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} display="flex" sx={{flex: 1, width: '100%'}}>
                            <Paper sx={{backgroundColor: 'secondary.light', padding: 4, borderRadius: 5, width: '100%'}}>
                                <Typography variant="body1">{selectedUser.person?.firstname} {selectedUser.person?.lastname} ({selectedUser.person?.gender})</Typography>
                                <Typography variant="body1">{selectedUser.person?.address?.line1}</Typography>
                                <Typography variant="body1">{selectedUser.person?.address?.zipCode} {selectedUser.person?.address?.city}, {selectedUser.person?.address?.country}</Typography>
                                <Typography variant="body1">{selectedUser.person?.phone}</Typography>
                                <Typography variant="body1">{selectedUser.person?.email}</Typography>
                                {selectedUser.pictureId}
                            </Paper>
                        </Grid>
                    </Grid>

                <Grid container spacing={3} sx={{mt: '.1rem'}} >
                    <Grid item xs={12} display="flex" sx={{flex: 1, width: '100%'}}>
                        <Typography variant={'h6'} color={'text.secondary'} fontWeight={700} sx={{ textTransform: 'uppercase', width: '100%' }}>Subscription & Entitlements</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} display="flex" sx={{flex: 1, width: '100%'}}>
                        <AboOverview subscriptions={subscriptions} maxAllowedSitesWithoutAddon={maxAllowedSitesWithoutAddon} totalOwnedSites={totalOwnedSites} userEntitlements={userEntitlements} userRole={userRole as User.userRole} editable={false} />
                    </Grid>
                    <Grid item xs={12} md={6} display="flex" sx={{flex: 1, width: '100%'}}>
                        <Paper sx={{backgroundColor: '#f5f5f5', padding: 4, borderRadius: 5, width: '100%', mt:'.1rem'}}>
                            <ul>
                                {userEntitlements.map(entitlement => (
                                    <li key={entitlement.featureId}>
                                        <Typography variant={'body2'}>{entitlement.featureName} (Value: {entitlement.value})</Typography>
                                    </li>
                                ))}
                            </ul>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid item xs={12} display="flex" sx={{flex: 1, width: '100%'}}>
                    <Typography variant={'h6'} color={'text.secondary'} fontWeight={700} sx={{ textTransform: 'uppercase', width: '100%' }}>Reiseübersicht</Typography>
                </Grid>
                <UserSpecificComponent />
                <Divider sx={{ my: 2 }} />
                <Box display="flex" justifyContent="center">
                    <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => setOpenDeleteDialog(true)}>Löschen</Button>
                </Box>
                <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
                    <DialogTitle>User löschen?</DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setOpenDeleteDialog(false)}>Abbrechen</Button>
                        <Button onClick={handleDelete} color="error">Löschen</Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Main>
    );
};

export default AgentUserDetailPage;
