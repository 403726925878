import React, { useEffect, useState } from 'react';
import {
    Card,
    CardContent,
    Typography,
    Box,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    LinearProgress,
    useTheme,
} from '@mui/material';

interface RoadmapItem {
    title: string;
    progress: number;
    description?: string;
}

interface RoadmapCardProps {
    markdownPath: string;
}

const parseMarkdown = (markdown: string): RoadmapItem[] => {
    const lines = markdown.split('\n');
    const items: RoadmapItem[] = [];

    lines.forEach((line) => {
        const trimmed = line.trim();

        const match = trimmed.match(/^[\-*–]\s*(.*?)\s*\((\d{1,3})%\)\s*:?\s*(.*)?$/);

        if (match) {
            items.push({
                title: match[1].trim(),
                progress: parseInt(match[2]),
                description: match[3]?.trim() || undefined,
            });
        } else {
        }
    });
    return items;
};

const getColor = (progress: number, theme: any): string => {
    if (progress < 20) return theme.palette.warning.light; // gelb
    if (progress < 50) return theme.palette.warning.main; // orange
    if (progress < 80) return theme.palette.success.light; // hellgrün
    return theme.palette.success.main; // grün
};

const NewsFeedCard: React.FC<RoadmapCardProps> = ({ markdownPath }) => {
    const [items, setItems] = useState<RoadmapItem[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const theme = useTheme();

    useEffect(() => {
        fetch(markdownPath)
            .then((res) => res.text())
            .then((text) => {
                const parsed = parseMarkdown(text);
                setItems(parsed);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    }, [markdownPath]);

    const overallProgress =
        items.length > 0
            ? items.reduce((acc, item) => acc + item.progress, 0) / items.length
            : 0;

    return (
        <Card sx={{ padding: 3, boxShadow: 4, borderRadius: 2 }}>
            <CardContent>
                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" py={3}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <List>
                            {items.map((item, idx) => (
                                <ListItem key={idx} alignItems="flex-start" sx={{ flexDirection: 'column', alignItems: 'stretch' }}>
                                    <Typography variant="subtitle1" fontWeight={600} gutterBottom>
                                        {item.title}
                                    </Typography>
                                    {item.description && (
                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                            {item.description}
                                        </Typography>
                                    )}
                                    <Box mb={2}>
                                        <Typography variant="caption" color="text.secondary">
                                            {item.progress}% abgeschlossen
                                        </Typography>
                                        <LinearProgress
                                            variant="determinate"
                                            value={item.progress}
                                            sx={{
                                                height: 10,
                                                borderRadius: 5,
                                                backgroundColor: theme.palette.grey[300],
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: getColor(item.progress, theme),
                                                },
                                            }}
                                        />
                                    </Box>
                                </ListItem>
                            ))}
                        </List>
                    </>
                )}
            </CardContent>
        </Card>
    );
};

export default NewsFeedCard;
