import React from 'react';
import {Compound} from "../../services/restserver-openapi";
import {Badge, BadgeProps, Box, Grid, Typography, useTheme} from "@mui/material";
import {Link} from "react-router-dom";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import ImageIcon from "@mui/icons-material/Image";
import CardContent from "@mui/material/CardContent";
import {createGeneralStyling} from "../../theme/generalStyling";
import {styled} from "@mui/system";
import {getReadableCompoundType} from "../../utils/typeUtils";

type CompoundsListingProp = {
    compounds: Compound[];
    title: string;
    hostId?: number;
}

const CompoundsListing = ({compounds, title, hostId}: CompoundsListingProp) => {
    const theme = useTheme();

    return (
        <Box>
            <Typography variant="h5" component="h2" gutterBottom>{title}</Typography>
            <Grid container spacing={2}>
                {[...compounds]
                    .sort((a, b) => (b.featured ? 1 : 0) - (a.featured ? 1 : 0)) // Sortierung: Featured zuerst
                    .map((compound, i, arr) => {
                    const isFeatured = compound.featured;
                    const firstNonFeaturedIndex = arr.findIndex(c => !c.featured);
                    const shouldStartNewRow = !isFeatured && i === firstNonFeaturedIndex;

                    return (
                        <React.Fragment key={i}>
                            {shouldStartNewRow && <Grid item xs={12} />} {/* Startet eine neue Zeile für nicht-Featured */}
                            <Grid item xs={12} sm={6} md={isFeatured ? 3 : 3} key={i}>
                                <Box
                                    component={Link}
                                    to={`/vanlifer/Campsite/${compound.compoundId}`}
                                    display={'block'}
                                    width={1}
                                    height={1}
                                    sx={{
                                        textDecoration: 'none',
                                        transition: 'all .2s ease-in-out',
                                        '&:hover': {
                                            transform: `translateY(-${theme.spacing(0.5)})`,
                                        },
                                    }}
                                >
                                    <Card
                                        sx={{
                                            // boxShadow: isFeatured ? `0px 4px 10px ${theme.palette.primary.light}` : 2,
                                            boxShadow: 2,
                                            backgroundColor: 'background.paper',
                                            position: 'relative', // Wichtig für das Positionieren des Badges
                                        }}
                                    >
                                        {/* Bild nur für Featured-Compounds anzeigen */}
                                        {isFeatured && compound.imageIds?.length! > 0 && (
                                            <Box position="relative">
                                                <CardMedia
                                                    component="img"
                                                    height={200}
                                                    image={`${process.env.REACT_APP_API_URL}/image/${compound.imageIds![0]}`}
                                                    alt={compound.name}
                                                    sx={{ objectFit: 'cover' }}
                                                />

                                                {/* Badge oben rechts über dem Bild */}
                                                <Badge
                                                    badgeContent="Featured"
                                                    color="info"
                                                    sx={{
                                                        position: 'absolute',
                                                        top: 15,
                                                        right: 35,
                                                        backgroundColor: theme.palette.success.main,
                                                        color: 'white',
                                                        px: 2,
                                                        borderRadius: '5px',
                                                        fontSize: '0.8rem',
                                                    }}
                                                />
                                            </Box>
                                        )}

                                        <CardContent sx={{ p: 2 }}>
                                            {/* Compound Type */}
                                            <Typography sx={{ textTransform: 'uppercase' }} fontSize={isFeatured ? '0.8rem' : '0.6rem'} gutterBottom>
                                                {getReadableCompoundType(compound!)}
                                            </Typography>
                                            {/* Compound Name */}
                                            <Typography variant={isFeatured ? 'h6' : 'body1'} fontSize={isFeatured ? '1rem' : '0.8rem'} gutterBottom>
                                                {compound.name}
                                            </Typography>
                                            {/* Stadt und Land */}
                                            <Typography color="text.secondary" fontSize={isFeatured ? '0.8rem' : '0.6rem'}>
                                                {compound.addressLine1} <br/> {compound.zipCode} {compound.city}, {compound.country}
                                            </Typography>

                                            {/* Kleines Bild am rechten Rand, wenn nicht Featured */}
                                            {!isFeatured && compound.imageIds?.length! > 0 && (
                                                <Box
                                                    component="img"
                                                    src={`${process.env.REACT_APP_API_URL}/image/${compound.imageIds![0]}`}
                                                    alt={compound.name}
                                                    sx={{
                                                        width: 90,
                                                        height: 90,
                                                        objectFit: 'cover',
                                                        borderRadius: '5px',
                                                        position: 'absolute',
                                                        top: 10,
                                                        right: 10,
                                                        boxShadow: 1,
                                                    }}
                                                />
                                            )}
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                        </React.Fragment>
                    );
                })}
            </Grid>
        </Box>
    );

};

export default CompoundsListing;