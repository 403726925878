import React, { useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Typography,
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Tooltip,
    TextField,
    Button,
    Divider,
} from '@mui/material';
import { ThumbUp, ThumbUpOutlined, ThumbDown, ThumbDownOutlined } from '@mui/icons-material';
import {useAppSelector} from "../../../hooks/hooks";

interface Feature {
    id: number;
    title: string;
    comment?: string;
}

const initialFeatures: Feature[] = [
    { id: 1, title: 'Sich einen bestimmten Gastgeber für eine Tour wünschen' },
    { id: 2, title: 'Eine alternative Unterkunft im Umkreis suchen' },
    { id: 3, title: 'Direkte Buchungen vornehmen' },
    { id: 4, title: 'Mehrsprachigkeit (EN, FR, ES)' },
];

const FeatureVotingCard: React.FC = () => {
    const person = useAppSelector((state) => state.user.user.person);

    const [features, setFeatures] = useState<Feature[]>(initialFeatures);
    const [votes, setVotes] = useState<{ [key: number]: 'up' | 'down' | null }>({});
    const [newFeature, setNewFeature] = useState<string>('');
    const [comments, setComments] = useState<{ [key: number]: string }>({});
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);

    const toggleVote = (id: number, type: 'up' | 'down') => {
        setVotes((prev) => {
            const current = prev[id];
            return { ...prev, [id]: current === type ? null : type };
        });
    };

    const handleAddFeature = () => {
        if (!newFeature.trim()) return;
        const nextId = features.length > 0 ? Math.max(...features.map(f => f.id)) + 1 : 1;
        setFeatures((prev) => [...prev, { id: nextId, title: newFeature }]);
        setVotes((prev) => ({ ...prev, [nextId]: 'up' }));
        setNewFeature('');
    };

    const handleSubmit = async () => {
        setSubmitting(true);
        const body = features.map(f => {
            const vote = votes[f.id] === 'up' ? '👍' : votes[f.id] === 'down' ? '👎' : '—';
            return `Feature: ${f.title}\nStimme: ${vote}${comments[f.id] ? `\nKommentar: ${comments[f.id]}` : ''}`;
        }).join('\n\n');

        const formData = new FormData();
        formData.append('Feedback', body);
        formData.append('PersonId', String(person?.personId ?? 'undefined'));


        try {
            const response = await fetch('https://formspree.io/f/movekjjz', {
                method: 'POST',
                body: formData,
                headers: {
                    Accept: 'application/json',
                },
            });

            if (response.ok) {
                setSubmitted(true);
            } else {
                console.error('Formspree error:', response);
            }
        } catch (err) {
            console.error('Formspree exception:', err);
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <Card sx={{ padding: 3, boxShadow: 4, borderRadius: 2 }}>
            <CardContent>
                <List>
                    {features.map((feature) => (
                        <ListItem key={feature.id} alignItems="flex-start" divider>
                            <ListItemText
                                primary={feature.title}
                                primaryTypographyProps={{ fontWeight: 500 }}
                                secondary={
                                    votes[feature.id] ? (
                                        <TextField
                                            fullWidth
                                            size="small"
                                            placeholder="Kommentar hinzufügen (optional)"
                                            value={comments[feature.id] || ''}
                                            onChange={(e) => setComments({ ...comments, [feature.id]: e.target.value })}
                                            variant="standard"
                                            multiline
                                        />
                                    ) : null
                                }
                            />
                            <ListItemSecondaryAction>
                                <Tooltip title="Gefällt mir">
                                    <IconButton onClick={() => toggleVote(feature.id, 'up')}>
                                        {votes[feature.id] === 'up' ? <ThumbUp /> : <ThumbUpOutlined />}
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Gefällt mir nicht">
                                    <IconButton onClick={() => toggleVote(feature.id, 'down')}>
                                        {votes[feature.id] === 'down' ? <ThumbDown /> : <ThumbDownOutlined />}
                                    </IconButton>
                                </Tooltip>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>

                <Divider sx={{ my: 3 }} />

                <Box display="flex" gap={2} flexDirection={{ xs: 'column', sm: 'row' }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        size="small"
                        label="Eigenes Feature vorschlagen"
                        value={newFeature}
                        onChange={(e) => setNewFeature(e.target.value)}
                    />
                    <Button variant="outlined" onClick={handleAddFeature}>
                        Hinzufügen
                    </Button>
                </Box>

                <Box mt={3} textAlign="center">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={submitting || submitted}
                    >
                        {submitted ? 'Erfolgreich gesendet' : 'Senden'}
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

export default FeatureVotingCard;