import React from 'react';
import ProfileSettingsPage from "./Page/ProfileSettingsPage";
import {Box, Typography} from "@mui/material";
import Main from "../../layouts/Main";
import {useAppSelector} from "../../hooks/hooks";
import {useNavigate} from "react-router-dom";
import Divider from "@mui/material/Divider";
import CompoundManagementComponent from "../../components/hostComponents/CompoundManagementComponent";
import {User} from "../../services/restserver-openapi";
import GamificationProfile from "../../components/vanliferComponents/Gamification/GamificationProfile";

export default function RoleSpecific() {
    const navigate = useNavigate();

    const userRoleLogin = useAppSelector((state) => state.login.userRoleLogin);
    if (userRoleLogin === undefined) {
        navigate("/profile/personal");
    }
    return (
        <Main>
            <ProfileSettingsPage>
                <Box>
                    { userRoleLogin === User.userRole.HOST &&
                    <>
                        <Typography variant={'body1'} color={'text.secondary'}>
                        Um Daten für einen anderen Betrieb zu bearbeiten, verwende den Wechsler oben rechts neben dem Profilbild.
                        </Typography>
                        <Box paddingY={4}>
                            <Divider />
                        </Box>
                        <CompoundManagementComponent />
                    </>}
                    { userRoleLogin === User.userRole.VANLIFER &&
                    <>
                        <Typography variant="h6" gutterBottom fontWeight={700}>
                            Auszeichnungen
                        </Typography>
                        <Typography variant={'subtitle2'} color={'text.secondary'}>
                            Hier siehst du deine Auszeichnungen und kannst sie verwalten.
                        </Typography>
                        <Box paddingY={4}>
                            <Divider />
                        </Box>
                        <GamificationProfile/>
                    </>}
                    { userRoleLogin === User.userRole.AGENT && <Box>AGENT</Box> }
                </Box>
            </ProfileSettingsPage>
        </Main>
    );
};
