import React, { useState, useEffect } from 'react';
import { useTheme, alpha } from '@mui/material/styles';
import {
    Box,
    Card,
    List,
    ListItem,
    Grid,
    Typography,
    useMediaQuery,
    Divider
} from '@mui/material';
import Container from '../../../components/Container';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../hooks/hooks';
import { User } from '../../../services/restserver-openapi';
import Main from '../../../layouts/Main';

const allPages = [
    { id: 'personal', href: '/profil/personal', title: 'Persönliche Daten' },
    { id: 'notifications', href: '/profil/notifications', title: 'Benachrichtigungen' },
    { id: 'abos', href: '/profil/abos', title: 'Meine Mitgliedschaft' },
    { id: 'support', href: '/profil/support', title: 'Hilfe & Support' },
];

interface Props {
    children: React.ReactNode;
}

const ProfileSettingsPage = ({ children }: Props): JSX.Element => {
    const theme = useTheme();
    const userRoleLogin = useAppSelector(state => state.login.userRoleLogin);
    const [pages, setPages] = useState(allPages);
    const [activeLink, setActiveLink] = useState('');
    const navigate = useNavigate();
    const mode = theme.palette.mode;
    const isMd = useMediaQuery(theme.breakpoints.up('md'));

    useEffect(() => {
        setActiveLink(window.location.pathname);
    }, []);

    useEffect(() => {
        if (userRoleLogin === User.userRole.AGENT) {
            setPages(allPages.filter(page => page.id !== 'abos'));
        } else {
            setPages(allPages);
        }
    }, [userRoleLogin]);

    useEffect(() => {
        if (!userRoleLogin) {
            navigate('/profile/personal');
        }
    }, [userRoleLogin, navigate]);

    return (
        <Main>
            <Container>
                <Box data-aos={isMd ? 'fade-right' : 'fade-up'}>
                    <Typography sx={{ textTransform: 'uppercase', fontWeight: 'medium' }} gutterBottom color={'text.secondary'}>
                        Einstellungen
                    </Typography>
                    <Typography variant="h2" color="text.primary" sx={{ fontWeight: 700 }}>
                        Verwalte{' '}
                        <Typography
                            color={'text.primary'}
                            component={'span'}
                            variant={'inherit'}
                            sx={{
                                background: `linear-gradient(180deg, transparent 82%, ${alpha(
                                    mode === 'light' ? theme.palette.secondary.main : theme.palette.primary.main,
                                    0.3
                                )} 0%)`,
                            }}
                        >
                            Dein Profil
                        </Typography>
                    </Typography>
                    <Box marginBottom={3}>
                        {userRoleLogin === User.userRole.HOST && (
                            <Typography variant="h6" component="p" color="text.secondary">
                                Es freut uns sehr, Dich als Gastgeber bei Vanlife Travel begrüßen zu dürfen. Wir möchten Dir die Verwaltung
                                Deiner Unterkünfte vereinfachen und Dir helfen, das Beste aus Deiner Gastgeberrolle herauszuholen. Solltest
                                Du Fragen haben oder Unterstützung benötigen, steht unser Support Team jederzeit gerne zur Verfügung. Wir
                                wünschen Dir viel Erfolg und freuen uns auf eine erfolgreiche Zusammenarbeit!
                            </Typography>
                        )}
                        {userRoleLogin === User.userRole.VANLIFER && (
                            <Typography variant="h6" component="p" color="text.secondary">
                                Schön dass Du Teil der Open Travel Community bist. Wir möchten Dir die Verwaltung Deines Profils
                                so einfach wie möglich machen. In diesem Bereich kannst Du Deine Profileinstellungen verwalten. Bitte
                                achte darauf, dass Du korrekte Daten angibst, denn sonst können wir die Gastgeber nicht informieren, wer zu
                                Ihnen kommt und Deine Reise kann nicht so stattfinden, wie Du es Dir gewünscht hast. Solltest Du Fragen oder
                                Unterstützung benötigen steht dir unser Support Team gerne zur Verfügung. Wir wünschen Dir viel Spass.
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <Card sx={{ boxShadow: 3, padding: 4 }}>
                            <List sx={{ display: 'flex', flexDirection: 'column', width: '100%', paddingY: 1 }}>
                                {pages.map(item => (
                                    <ListItem
                                        key={item.id}
                                        component={Link}
                                        to={item.href}
                                        sx={{
                                            paddingX: 2,
                                            borderLeft: activeLink === item.href ? `5px solid ${theme.palette.secondary.light}` : 'none',
                                        }}
                                    >
                                        <Typography variant="subtitle1" color={activeLink === item.href ? 'text.primary' : 'text.secondary'}>
                                            {item.title}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </List>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Card sx={{ boxShadow: 3, padding: 4 }}>{children}</Card>
                    </Grid>
                </Grid>
            </Container>
        </Main>
    );
};

export default ProfileSettingsPage;
