import * as React from 'react';
import Tooltip from "@mui/material/Tooltip";
import PowerIcon from "@mui/icons-material/Power";
import WaterIcon from "@mui/icons-material/WaterDrop";
import WifiIcon from "@mui/icons-material/Wifi";
import ShopIcon from "@mui/icons-material/ShoppingCart";
import SewerageIcon from "@mui/icons-material/OilBarrel";
import MountainIcon from "@mui/icons-material/Landscape";
import WaterBodyIcon from "@mui/icons-material/Water";
import CulturalOfferingsIcon from "@mui/icons-material/TheaterComedy";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import BreakfastDiningIcon from "@mui/icons-material/LocalDining";
import RoomServiceIcon from "@mui/icons-material/RoomService";
import TwentyFourHoursIcon from "@mui/icons-material/AccessTime";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import ParkingIcon from "@mui/icons-material/EmojiTransportation";
import DirectionsBusIcon from "@mui/icons-material/DirectionsTransit";
import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly";
import SportsSoccerIcon from "@mui/icons-material/SportsSoccer";
import GrillIcon from "@mui/icons-material/OutdoorGrill";
import PlayGroundIcon from "@mui/icons-material/Toys";
import PoolIcon from "@mui/icons-material/Pool";
import HotTubIcon from "@mui/icons-material/HotTub";
import MassageIcon from "@mui/icons-material/Spa";
import SaunaIcon from "@mui/icons-material/HeatPump";
import PetsIcon from "@mui/icons-material/Pets";
import SmokeFreeIcon from "@mui/icons-material/SmokeFree";
import TvIcon from "@mui/icons-material/Tv";
import KitchenIcon from "@mui/icons-material/Kitchen";
import LiquorIcon from "@mui/icons-material/Liquor";
import BathroomIcon from "@mui/icons-material/Bathroom";
import ShowerIcon from "@mui/icons-material/Shower";
import BathtubIcon from "@mui/icons-material/Bathtub";
import BalconyIcon from "@mui/icons-material/Balcony";
import ToiletIcon from "@mui/icons-material/Wc";

import { Typography, Box } from "@mui/material";
import { Compound, Site } from "../../services/restserver-openapi";
import { StayCompound, StaySite } from '../../services/restserver-openapi';
import { EcoFriendlyIcon } from "../vanliferComponents/EcoFriendlyIcon";

interface CompoundEquipmentProps {
    compound?: StayCompound | Compound | undefined | null;
    site?: Site | StaySite | undefined;
    largeIcons?: boolean;
    showTextIfNoEquipment?: boolean;
    layout?: "icons" | "text"; // Neuer Parameter
}

export function CompoundEquipment({
                                      compound,
                                      site,
                                      largeIcons = false,
                                      showTextIfNoEquipment = false,
                                      layout = "icons", // Standardmäßig Tooltip-Layout
                                  }: CompoundEquipmentProps): React.ReactElement {
    const fontSize = largeIcons ? "large" : "medium";

    interface SiteAttribute {
        attribute: boolean | undefined;
        icon: React.ReactElement;
        title: string;
    }

    const allAttributes: SiteAttribute[] = [
        { attribute: site?.electricity, icon: <PowerIcon fontSize={fontSize} />, title: "Strom" },
        { attribute: site?.water, icon: <WaterIcon fontSize={fontSize} />, title: "Wasseranschluss" },
        { attribute: site?.petsAllowed, icon: <PetsIcon fontSize={fontSize} />, title: "Haustiere erlaubt" },
        { attribute: site?.nonSmoking, icon: <SmokeFreeIcon fontSize={fontSize} />, title: "Nichtraucher" },
        { attribute: site?.tvConnection, icon: <TvIcon fontSize={fontSize} />, title: "TV/ Kabelanschluss" },
        { attribute: site?.cookingFacility, icon: <KitchenIcon fontSize={fontSize} />, title: "Kochgelegenheit" },
        { attribute: site?.minibar, icon: <LiquorIcon fontSize={fontSize} />, title: "Minibar" },
        { attribute: site?.sharedBathroom, icon: <ToiletIcon fontSize={fontSize} />, title: "WC zur Mitbenutzung" },
        { attribute: site?.sharedShower, icon: <BathroomIcon fontSize={fontSize} />, title: "Dusche zur Mitbenutzung" },
        { attribute: site?.privateBathroom, icon: <BathtubIcon fontSize={fontSize} />, title: "Eigenes WC" },
        { attribute: site?.privateShower, icon: <ShowerIcon fontSize={fontSize} />, title: "Eigene Dusche" },
        { attribute: site?.refrigerator, icon: <KitchenIcon fontSize={fontSize} />, title: "Kühlschrank" },
        { attribute: site?.balconyOrTerrace, icon: <BalconyIcon fontSize={fontSize} />, title: "Balkon/Terrasse" },
        { attribute: compound?.wifiAvailable, icon: <WifiIcon fontSize={fontSize} />, title: "WiFi" },
        { attribute: compound?.sauna, icon: <SaunaIcon fontSize={fontSize} />, title: "Sauna" },
        { attribute: compound?.massage, icon: <MassageIcon fontSize={fontSize} />, title: "Massage od. Wellness" },
        { attribute: compound?.hotTubOrSpa, icon: <HotTubIcon fontSize={fontSize} />, title: "Whirlpool / SPA Pool" },
        { attribute: compound?.swimmingPool, icon: <PoolIcon fontSize={fontSize} />, title: "Schwimmbad" },
        { attribute: compound?.cityNearby, icon: <LocationCityIcon fontSize={fontSize} />, title: "Stadt in der Nähe" },
        { attribute: compound?.mountains, icon: <MountainIcon fontSize={fontSize} />, title: "Berge in der Nähe" },
        { attribute: compound?.waterbody, icon: <WaterBodyIcon fontSize={fontSize} />, title: "Gewässer in der Nähe" },
        { attribute: compound?.culturalSiteOrAttraction, icon: <CulturalOfferingsIcon fontSize={fontSize} />, title: "Kultur / Sehenswürdigkeit" },
        { attribute: compound?.cateringOrRestaurant, icon: <RestaurantIcon fontSize={fontSize} />, title: "Verpflegung / Restaurant" },
        { attribute: compound?.onlyBreakfastPossible, icon: <BreakfastDiningIcon fontSize={fontSize} />, title: "Nur Frühstück möglich" },
        { attribute: compound?.breadRollService, icon: <RoomServiceIcon fontSize={fontSize} />, title: "Brötchenservice" },
        { attribute: compound?.wholeDayReception, icon: <TwentyFourHoursIcon fontSize={fontSize} />, title: "24h Rezeption" },
        { attribute: compound?.ownParkingSpace, icon: <LocalParkingIcon fontSize={fontSize} />, title: "Eigener Parkplatz" },
        { attribute: compound?.parkingNearby, icon: <ParkingIcon fontSize={fontSize} />, title: "Parkplatz in der Nähe" },
        { attribute: compound?.publicTransportation, icon: <DirectionsBusIcon fontSize={fontSize} />, title: "ÖV in der Nähe" },
        { attribute: compound?.sustainable, icon: <EcoFriendlyIcon fontSize={fontSize} />, title: "Nachhaltiger Betrieb" },
        { attribute: compound?.childFriendly, icon: <ChildFriendlyIcon fontSize={fontSize} />, title: "Kinderfreundlich" },
        { attribute: compound?.shopOrSupermarket, icon: <ShopIcon fontSize={fontSize} />, title: "Shop / Supermarkt" },
        { attribute: compound?.playGround, icon: <PlayGroundIcon fontSize={fontSize} />, title: "Spielplatz" },
        { attribute: compound?.wasteWaterDisposal, icon: <SewerageIcon fontSize={fontSize} />, title: "Abwasser / Entsorgung" },
        { attribute: compound?.freshWater, icon: <WaterIcon fontSize={fontSize} />, title: "Frischwasser" },
        { attribute: compound?.sportOrAnimation, icon: <SportsSoccerIcon fontSize={fontSize} />, title: "Sport / Animation" },
        { attribute: compound?.grillOrBarbecueArea, icon: <GrillIcon fontSize={fontSize} />, title: "Grill / Grillstelle" },
    ];

    const visibleAttributes = allAttributes.filter((a) => a.attribute);

    if (showTextIfNoEquipment && visibleAttributes.length === 0) {
        return <Typography>Keine Ausstattungen erfasst</Typography>;
    }

    if (layout === "text") {
        // Layout mit Text + Grid
        return (
            <Box display="grid" gridTemplateColumns="repeat(auto-fit, minmax(180px, 1fr))" gap={2}>
                {visibleAttributes.map((a, i) => (
                    <Box key={i} display="flex" alignItems="center" gap={1}>
                        {a.icon}
                        <Typography variant="body2">{a.title}</Typography>
                    </Box>
                ))}
            </Box>
        );
    }

    // Standard-Layout: nur Icons mit Tooltip
    return (
        <Typography component="div" display="flex" flexWrap="wrap" gap={2}>
            {visibleAttributes.map((a, i) => (
                <Tooltip title={a.title} key={i}>
                    <Box>{a.icon}</Box>
                </Tooltip>
            ))}
        </Typography>
    );
}
